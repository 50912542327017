import React from "react";
import { useFormikContext } from "formik";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";

import {
  isElectricalInfoEnabled,
  isMechanicalInfoEnabled,
  isOtherInfoEnabled,
  isPlumbingInfoEnabled,
} from "../../utils/form";
import OtherInfoFields from "../../components/form/fields/OtherInfoFields";
import MechanicalInfoFields from "../../components/form/fields/MechanicalInfoFields";
import PlumbingInfoFields from "../../components/form/fields/PlumbingInfoFields";
import ElectricalInfoFields from "../../components/form/fields/ElectricalInfoFields";
import FieldsIdentifiers from "../../views/capture/FieldsIdentifiers";
import FieldsDescriptive from "../../views/capture/FieldsDescriptive";
import FieldsStorage from "../../views/capture/FieldsStorage";

import FieldsOrigin from "../../views/capture/FieldsOrigin";
import FieldsDestination from "../../views/capture/FieldsDestination";
import ActivityLog from "../../views/asset/ActivityLog";
import { SustainabilitySheetProvider } from "../../views/capture/SustainabilitySheetContext";
import useCaptureFields from "../../hooks/capture/useCaptureFields";
import PackingField from "./form/PackingField";

export const warrantyOptions = [
  "1 year",
  "3 years",
  "5 years",
  "10 years",
  "Lifetime Warranty",
].map(v => [v, v]);

export default function Form({
  clientId,
  overrideDisplay,
  type,
  disabledForm,
  displayClient,
  displayProject,
  colorsOptions = [],
  manufacturersOptions = [],
  asset,
  updateAsset,
  createImage,
  setErrors,
  defaultTab = "replenish_inventory",
  overrideDisplayFields,
}) {
  const displayFields = useCaptureFields({ projectId: asset.projectId });
  const display = overrideDisplayFields ?? displayFields;
  const { values, setFieldValue } = useFormikContext();
  const isElectricalInfoSectionEnabled = isElectricalInfoEnabled(display);
  const isPlumbingInfoSectionEnabled = isPlumbingInfoEnabled(display);
  const isMechanicalInfoSectionEnabled = isMechanicalInfoEnabled(display);
  const isOtherInfoSectionEnabled = isOtherInfoEnabled(display);

  const handleUpdateAssetField = field => (value, { onSuccess, onFailure } = {}) => {
    const newValue = field === "barcode" && values.barcode ? `${values.barcode}, ${value}` : value;

    if (field === "barcode") {
      setFieldValue(field, newValue);
    }

    updateAsset({
      variables: { id: asset.id, input: { [field]: newValue } },
      onSuccess,
      onFailure: errors => {
        setErrors(errors);
        onFailure && onFailure(errors);
      },
    });
  };

  return (
    <>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <FieldsIdentifiers
            displayClient={displayClient}
            displayProject={displayProject}
            clientId={clientId}
            display={display}
            type={type}
            disabledForm={disabledForm}
            asset={asset}
            createImage={createImage}
            handleUpdateAssetField={handleUpdateAssetField}
          />
        </Grid>
        <Grid item xs={12}>
          <SustainabilitySheetProvider>
            <FieldsDescriptive
              display={display}
              asset={asset}
              disabledForm={disabledForm}
              colorsOptions={colorsOptions}
              manufacturersOptions={manufacturersOptions}
            />
          </SustainabilitySheetProvider>
        </Grid>
        <Grid item xs={12}>
          <FieldsStorage
            display={display}
            type={type}
            disabledForm={disabledForm}
            asset={asset}
            colorsOptions={colorsOptions}
            manufacturersOptions={manufacturersOptions}
          />
        </Grid>
        <Grid item xs={12}>
          <FieldsOrigin
            display={display}
            asset={asset}
            disabledForm={disabledForm}
            colorsOptions={colorsOptions}
            manufacturersOptions={manufacturersOptions}
          />
        </Grid>
        <Grid item xs={12}>
          <FieldsDestination
            display={display}
            type={type}
            asset={asset}
            disabledForm={disabledForm}
            colorsOptions={colorsOptions}
            manufacturersOptions={manufacturersOptions}
          />
        </Grid>
        {isElectricalInfoSectionEnabled && (
          <Grid item xs={12}>
            <Card style={{ overflow: "visible" }}>
              <CardContent>
                <ElectricalInfoFields display={display} disabledForm={disabledForm} />
              </CardContent>
            </Card>
          </Grid>
        )}
        {isPlumbingInfoSectionEnabled && (
          <Grid item xs={12}>
            <Card style={{ overflow: "visible" }}>
              <CardContent>
                <PlumbingInfoFields display={display} disabledForm={disabledForm} />
              </CardContent>
            </Card>
          </Grid>
        )}
        {isMechanicalInfoSectionEnabled && (
          <Grid item xs={12}>
            <Card style={{ overflow: "visible" }}>
              <CardContent>
                <MechanicalInfoFields display={display} disabledForm={disabledForm} />
              </CardContent>
            </Card>
          </Grid>
        )}
        {isOtherInfoSectionEnabled && (
          <Grid item xs={12}>
            <Card style={{ overflow: "visible" }}>
              <CardContent>
                <OtherInfoFields display={display} disabledForm={disabledForm} />
              </CardContent>
            </Card>
          </Grid>
        )}
        <Grid item xs={12}>
          <Card style={{ overflow: "visible" }}>
            <CardContent>
              <ActivityLog assetId={asset.id} defaultTab={defaultTab} />
            </CardContent>
          </Card>
        </Grid>
        {display.packing && (
          <Grid item xs={12}>
            <Card>
              <CardContent>
                <PackingField />
              </CardContent>
            </Card>
          </Grid>
        )}
      </Grid>
    </>
  );
}
