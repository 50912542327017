import React from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import InsertChartOutlinedIcon from "@mui/icons-material/InsertChartOutlined";
import InsightsOutlinedIcon from "@mui/icons-material/InsightsOutlined";
import useMediaQuery from "@mui/material/useMediaQuery";

import WarehouseFilter from "../WarehouseFilter";
import Toolkit from "../Toolkit";
import TableSearch from "../TableSearch";
import WarehouseName from "./WarehouseName";
import WidgetCard from "./WidgetCard";

export default function WarehouseHeader({
  view,
  warehouse,
  warehouseLocations,
  counter,
  handleRefetch,
  selectedProjects,
  setSelectedProjects,
  changeSearch,
  modal,
  changeWarehouse,
  changeView,
  occupied,
  setOccupied,
  height,
  setHeight,
}) {
  const isMobile = useMediaQuery(theme => theme.breakpoints.down("sm"));
  const isDesktop = useMediaQuery(theme => theme.breakpoints.up("md"));
  const emptySlots = warehouseLocations.filter(v => v.occupied === false).length;
  const occupiedSlots = warehouseLocations.filter(v => v.occupied === true).length;
  const storageSpaceUtilization = (100 / warehouseLocations.length) * occupiedSlots;

  const lg = modal ? 4 : 3;

  return (
    <>
      <Grid container spacing={{ xs: 1, sm: 2, md: 3 }}>
        <Grid item xs={4} lg={lg}>
          <WarehouseName warehouse={warehouse} changeWarehouse={changeWarehouse} />
        </Grid>
        <Grid item xs={4} lg={lg}>
          <WidgetCard
            value={emptySlots}
            label={isMobile ? "Empty" : "Empty Locations"}
            icon={
              <Box display="inline-block" bgcolor="#fff8db" p={3} borderRadius="50%">
                <InsightsOutlinedIcon htmlColor="#efbe25" />
              </Box>
            }
          />
        </Grid>
        <Grid item xs={4} lg={lg}>
          <WidgetCard
            value={`${parseInt(storageSpaceUtilization || 0, 10)}%`}
            label="Occupied"
            icon={
              <Box display="inline-block" bgcolor="#d6d2d0" p={3} borderRadius="50%">
                <InsertChartOutlinedIcon htmlColor="#4e4843" />
              </Box>
            }
          />
        </Grid>
      </Grid>
      <Box mb={2} />
      {!isDesktop || modal ? (
        <>
          <WarehouseFilter
            occupied={occupied}
            selected={selectedProjects}
            setSelected={setSelectedProjects}
            setOccupied={setOccupied}
            height={height}
            setHeight={setHeight}
          />
          <Box display="flex" alignItems="center" justifyContent="space-between">
            <Box mt={1} lineHeight={1}>
              {counter} Locations
            </Box>
            <Toolkit refetch={handleRefetch} view={view} changeView={changeView} />
          </Box>
          <Box mt={1}>
            <TableSearch placeholder="Search" onChange={changeSearch} value="" />
          </Box>
        </>
      ) : (
        <>
          <Box pb={2} lineHeight={1}>
            {counter} Locations
          </Box>
          <Box display="flex">
            <Box flexGrow={1}>
              <Box display="flex" alignItems="center" justifyContent="space-between">
                <Box display="flex" justifyContent="flex-start" width="33.3%">
                  <TableSearch placeholder="Search" onChange={changeSearch} value="" />
                </Box>
                <Box display="flex" justifyContent="center" width="33.3%">
                  <WarehouseFilter
                    occupied={occupied}
                    selected={selectedProjects}
                    setSelected={setSelectedProjects}
                    setOccupied={setOccupied}
                    height={height}
                    setHeight={setHeight}
                  />
                </Box>
                <Box display="flex" justifyContent="flex-end" width="33.3%">
                  <Toolkit refetch={handleRefetch} view={view} changeView={changeView} />
                </Box>
              </Box>
            </Box>
          </Box>
        </>
      )}
    </>
  );
}
