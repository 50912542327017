import * as Yup from "yup";
import React from "react";
import { Formik } from "formik";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";
import CircularProgress from "@mui/material/CircularProgress";

import Input from "../../../form/Input";

const schema = Yup.object().shape({
  destinationRoomNumbers: Yup.array().of(
    Yup.object().shape({
      room_number: Yup.string().required("Required"),
    })
  ),
});

const initDestinationRoom = { room_number: "", sub_qty: 0 };

export default function MultipleDestinationRoomNumberForm({
  loading,
  destinationRoomNumbers,
  onSubmit,
}) {
  return (
    <Formik
      initialValues={{
        destinationRoomNumbers: destinationRoomNumbers.length
          ? destinationRoomNumbers
          : [initDestinationRoom],
      }}
      onSubmit={onSubmit}
      validationSchema={schema}
    >
      {({ values, handleSubmit, setFieldValue }) => (
        <form onSubmit={handleSubmit}>
          <Grid container spacing={1}>
            {values.destinationRoomNumbers.map((location, index) => {
              const number = index + 1;
              return (
                <React.Fragment key={index}>
                  <Grid item sm={7}>
                    <Input
                      name={`destinationRoomNumbers[${index}].room_number`}
                      label={`Destination Room Number ${number}`}
                    />
                  </Grid>
                  <Grid item sm={5}>
                    <Box display="flex" justifyContent="space-between">
                      <Input
                        name={`destinationRoomNumbers[${index}].sub_qty`}
                        label={`Sub QTY ${number}`}
                      />
                      <IconButton
                        sx={{ ml: 1 }}
                        onClick={() => {
                          setFieldValue(
                            "destinationRoomNumbers",
                            values.destinationRoomNumbers.filter((v, i) => i !== index)
                          );
                        }}
                      >
                        <DeleteIcon color="action" />
                      </IconButton>
                    </Box>
                  </Grid>
                </React.Fragment>
              );
            })}
          </Grid>
          <>
            <Box mt={2} />
            <Button
              variant="outlined"
              color="primary"
              onClick={() => {
                setFieldValue("destinationRoomNumbers", [
                  ...values.destinationRoomNumbers,
                  initDestinationRoom,
                ]);
              }}
            >
              Add New
            </Button>
          </>
          <Box mt={4} />
          <Box display="flex" justifyContent="flex-end">
            <Button
              color="primary"
              variant="contained"
              type="submit"
              disabled={loading}
              startIcon={loading && <CircularProgress size={16} />}
            >
              Save
            </Button>
          </Box>
        </form>
      )}
    </Formik>
  );
}
