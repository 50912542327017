import React, { useState } from "react";
import PropTypes from "prop-types";
import { Link as RouterLink } from "react-router-dom";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid";
import Link from "@mui/material/Link";
import Typography from "@mui/material/Typography";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";

import { pricer } from "../../../utils/money";
import { useShopping } from "../../../contexts/shopping";
import { usePermissions } from "../../../contexts/permissions";
import useQuery from "../../../hooks/useQuery";
import ActivityLog from "../../asset/ActivityLog";
import AssetInfo from "../../../views/shopping/ProjectAssets/AssetInfo/AssetInfo";
import AddToInventoryRequest from "./AddToInventoryRequest";
import AddToMyOrders from "./AddToMyOrders";
import AddToCart from "./AddToCart";
import AddToKit from "./AddToKit";
import AssetCardTitle from "./AssetCardTitle";
import useAuth from "../../../hooks/useAuth";
import AssetCardImage from "./AssetCardImage";

const styles = {
  pinkCard: {
    position: "relative",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    height: "100%",
    background: "#fce4ec",
  },
  card: {
    position: "relative",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    height: "100%",
  },
};

const AssetCard = ({ asset, display, defaultValues = {}, onClickImage }) => {
  const { user } = useAuth();
  const [openActivityLog, setOpenActivityLog] = useState(false);
  const { can } = usePermissions();
  const query = useQuery();
  const { inventoryItems } = useShopping();
  const inventoryItem = inventoryItems[asset.id];
  const isShoppingOrder = location.pathname.includes("shopping/orders");
  const isKitting = location.pathname.includes("kitting");
  const { settings } = user.company;
  const cardStyles =
    display.sc_po_number && asset.poNumber && asset.availableQuantity === 0
      ? styles.pinkCard
      : styles.card;

  const toggleActivityLog = () => setOpenActivityLog(v => !v);

  return (
    <Card sx={cardStyles}>
      <Box px={3} pt={3} pb={1}>
        <AssetCardImage display={display} asset={asset} onClickImage={onClickImage} />
        <AssetCardTitle display={display} asset={asset} />
      </Box>
      {display.sc_storage_location && (
        <Box pt={2} display="flex" flexDirection="column" alignItems="center">
          <Typography variant="body2" color="textSecondary">
            {settings.filter_field2}
          </Typography>
          <Link
            fontWeight={500}
            color="textSecondary"
            to={`/shopping/${asset.project.id}`}
            component={RouterLink}
          >
            {asset.project.name}
          </Link>
        </Box>
      )}
      {display.sc_available_qty && (
        <Box pt={1} pl={5} display="flex" justifyContent="center" alignItems="center">
          <Box mr={0.5}>
            <Typography id={`availableQuantity-${asset.id}`} fontWeight={500} color="textPrimary">
              {asset.availableQuantity}
            </Typography>
          </Box>
          <Typography fontWeight={500} color="textPrimary">
            Available
          </Typography>
          <Box mb={0.25}>
            <AssetInfo asset={asset} />
          </Box>
          {openActivityLog && (
            <Dialog
              open
              fullWidth
              maxWidth="lg"
              onClose={toggleActivityLog}
              aria-labelledby="dialog-title"
            >
              <DialogContent>
                <DialogContentText>
                  <ActivityLog assetId={asset.id} defaultTab="reserved_inventory" />
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Box display="flex" p={1.5}>
                  <Button variant="outlined" onClick={toggleActivityLog} color="primary">
                    Close
                  </Button>
                </Box>
              </DialogActions>
            </Dialog>
          )}
        </Box>
      )}
      {settings.reservations && (
        <Box pt={1} display="flex" justifyContent="center">
          <Box mr={0.5}>
            <Typography fontWeight={500} color="textSecondary">
              {asset.reservedQuantity}
            </Typography>
          </Box>
          <Typography fontWeight={500} color="textSecondary">
            Reserved
          </Typography>
        </Box>
      )}
      <Box pb={2} px={3} mt={1}>
        <Grid container justify="space-between" spacing={3}>
          {can("pages.shopping_cart") && display.sc_price && (
            <Grid item xs={4} container justify="flex-end">
              <Box>
                <Typography variant="body2" color="textSecondary">
                  Price
                </Typography>
                <Typography id={`totalPrice-${asset.id}`} fontWeight={500} color="textPrimary">
                  {pricer(asset.price)}
                </Typography>
              </Box>
            </Grid>
          )}
        </Grid>
      </Box>
      {can("pages.shopping_cart") && (
        <>
          <Divider />
          <Box display="flex" justifyContent="space-between" py={2} px={3}>
            {isKitting ? (
              <AddToKit size="small" asset={asset} defaultValues={defaultValues} />
            ) : isShoppingOrder ? (
              <AddToMyOrders size="small" asset={asset} defaultValues={defaultValues} />
            ) : query.get("inventoryRequestId") ? (
              <AddToInventoryRequest size="small" asset={asset} defaultValues={defaultValues} />
            ) : (
              <AddToCart size="small" asset={asset} defaultValues={defaultValues} />
            )}
            {inventoryItem?.quantity && display.sc_price && (
              <Box>
                <Typography variant="body2" color="textSecondary">
                  Total
                </Typography>
                <Typography id={`requestedPrice-${asset.id}`} variant="h4" color="textPrimary">
                  {pricer(inventoryItem.quantity * asset.price)}
                </Typography>
              </Box>
            )}
          </Box>
        </>
      )}
    </Card>
  );
};

AssetCard.propTypes = {
  className: PropTypes.string,
  asset: PropTypes.object.isRequired,
};

export default AssetCard;
