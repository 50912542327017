import React from "react";
import { Formik } from "formik";
import Box from "@mui/material/Box";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import DialogContent from "@mui/material/DialogContent";

import { CaptureProvider } from "../../../contexts/capture";
import { ClientsProvider, useClients } from "../../../contexts/clients";
import CollectionProvider from "../../../views/capture/CollectionProvider";
import { AssetsProvider } from "../../../contexts/assets";
import { fetchAssetValues } from "../../../utils/asset";
import FormFields from "../../../views/capture/FormFields";
import { ProjectInventoryProvider } from "../../../contexts/projectInventory";

export default function AssetDetailsModal({ asset, onClose }) {
  const { clients } = useClients();
  const display = clients.flatMap(v => v.projects).find(v => v.id === asset.projectId)
    ?.fieldSettings;

  return (
    <Dialog open fullWidth maxWidth="md" onClose={onClose}>
      <DialogTitle sx={{ background: "#f4f6f8", display: "flex", justifyContent: "space-between" }}>
        <Box width="48px" />
        <Typography fontSize={24} fontWeight={500} color="textPrimary">
          Asset Details
        </Typography>
        <IconButton onClick={onClose}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent sx={{ background: "#f4f6f8" }}>
        <Box display="flex" flexGrow={1}>
          <ProjectInventoryProvider>
            <CaptureProvider>
              <ClientsProvider>
                <CollectionProvider>
                  <AssetsProvider>
                    <Formik key={asset?.id} initialValues={fetchAssetValues(asset)}>
                      <FormFields
                        disabledForm
                        type="orders"
                        asset={asset}
                        loading={false}
                        displayClient={true}
                        displayProject={true}
                        overrideDisplayFields={display}
                      />
                    </Formik>
                  </AssetsProvider>
                </CollectionProvider>
              </ClientsProvider>
            </CaptureProvider>
          </ProjectInventoryProvider>
        </Box>
      </DialogContent>
    </Dialog>
  );
}
