import React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import useAuth from "src/hooks/useAuth";

export default function InboundNoResults({ onSearchAll }) {
  const { user } = useAuth();
  const { settings } = user.company;

  return (
    <Box p={2} textAlign="center">
      <Typography>
        No results found. Would you like to search through all {settings.filter_field3}?
      </Typography>
      <Box py={2}>
        <Button variant="contained" color="primary" onClick={onSearchAll}>
          Search All {settings.filter_field3}
        </Button>
      </Box>
    </Box>
  );
}
