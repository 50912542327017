import React, { useState } from "react";
import { Field, useFormikContext } from "formik";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import useAuth from "../../../hooks/useAuth";

export default function HeaderField({ clientProjectName, name, ...props }) {
  const [focused, setFocused] = useState(false);
  const { user } = useAuth();
  const { setFieldValue, values } = useFormikContext();

  const handleClick = () => {
    setFieldValue(clientProjectName, true);
    setFocused(false);
  };

  const handleClear = () => {
    setFieldValue(clientProjectName, false);
    setFieldValue(name, "");
  };

  const getClientProjectValue = () => {
    const { filter_field1, filter_field2 } = user.company.settings;
    return `${filter_field1} - ${filter_field2} Variable`;
  };

  return (
    <Box position="relative">
      <Field
        name={name}
        render={({ field, form, meta }) => {
          const errorMessage = meta.error || form.errors[field.name];
          const error = (meta.touched || form.submitCount > 0) && errorMessage;
          const isClientProject = values[clientProjectName];
          const clientProjectValue = isClientProject ? getClientProjectValue() : field.value || "";

          return (
            <Box display="flex" gap={1}>
              <TextField
                fullWidth
                {...field}
                value={clientProjectValue}
                {...props}
                size="small"
                autoComplete="off"
                error={!!error}
                helperText={error}
                onFocus={() => !isClientProject && setFocused(true)}
                onBlur={() => setFocused(false)}
                disabled={isClientProject}
              />
              {isClientProject && (
                <Button variant="outlined" onClick={handleClear} sx={{ minWidth: "fit-content" }}>
                  Clear
                </Button>
              )}
            </Box>
          );
        }}
      />
      {focused && (
        <Box
          zIndex={999}
          mt={0.5}
          top="100%"
          position="absolute"
          left={0}
          right={0}
          display="flex"
          justifyContent="flex-end"
        >
          <Box bgcolor="white">
            <Button fullWidth onMouseDown={handleClick} variant="outlined">
              {getClientProjectValue()}
            </Button>
          </Box>
        </Box>
      )}
    </Box>
  );
}
