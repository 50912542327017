import React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CircularProgress from "@mui/material/CircularProgress";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import FormControl from "@mui/material/FormControl";

import FormLabel from "../../../components/FormLabel";
import RadioGroup from "../../../components/form/RadioGroup";
import Input from "../../../components/form/Input";
import CheckboxInput from "../../../components/form/Checkbox";
import IncludeReportFields from "../../../views/settings/IncludeReportFields";
import { useFavoriteReportFields } from "../../../contexts/FavoriteReportFields";
import Layer1Fields from "./Layer1Fields";
import Layer2Fields from "./Layer2Fields";
import Layer3Fields from "./Layer3Fields";
import NotificationField from "./NotificationField";
import ColumnOrderField from "./ColumnOrderField";
import GroupByFields from "./GroupByFields";
import FilterFields from "./FilterFields";
import useAuth from "../../../hooks/useAuth";
import HeaderField from "./HeaderField";

export const TYPE_OPTIONS = [
  ["tabular", "Tabular"],
  ["page_large_photo", "Tabular w/ Large Images"],
];

const STYLE_OPTIONS = [
  ["portrait", "Portrait"],
  ["landscape", "Landscape"],
];

const PAGE_SIZE_OPTIONS = [
  ["8.5x11", `Letter (8.5"x11")`],
  ["11x17", `Tabloid (11"x17")`],
];

export const FORMAT_OPTIONS = [
  ["inventory", "Inventory"],
  ["spec_sheet", "Spec Sheet"],
];

const FIELDS_LIMIT = 52;

function Content({ children }) {
  return (
    <Card>
      <CardContent>{children}</CardContent>
    </Card>
  );
}

const FavouriteReportFields = ({ isEdit, format, isSubmitting }) => {
  const isFormatPacking = format === "packing";
  const { toggleField, selectedFields: fields } = useFavoriteReportFields();
  const { user } = useAuth();

  const Checkbox = props => {
    const name = `${props.category}.${props.name}`;
    const disabled = fields.includes(props.name) ? false : fields.length > FIELDS_LIMIT;

    return <CheckboxInput {...props} onChange={toggleField} disabled={disabled} name={name} />;
  };

  // Hide fields based on medical fields toggle
  const hideFields = [
    "shipments",
    "inventory_items",
    "inventory_request",
    "shopping_cart",
    "orders",
    "reservations",
  ];

  if (!user.company.settings.medical_fields) {
    hideFields.push("electrical_info", "plumbing_info", "mechanical_info");
  }

  return (
    <>
      <Card sx={{ height: "100%" }}>
        <CardContent>
          <Grid container spacing={1}>
            <Grid item xs={12} md={3}>
              <FormLabel>Report Setup</FormLabel>
              <Box pr={{ md: 5 }}>
                <Input name="name" label="Template Name" />
              </Box>
            </Grid>
            <Grid item xs={12} md={2}>
              <RadioGroup name="style" label="Page Orientation" options={STYLE_OPTIONS} />
            </Grid>
            <Grid item xs={12} md={2}>
              <RadioGroup name="page_size" label="Page size" options={PAGE_SIZE_OPTIONS} />
            </Grid>
            <Grid item xs={12} md={2}>
              <RadioGroup name="format" label="Report Type" options={FORMAT_OPTIONS} />
            </Grid>
            <Grid item xs={12} md={2}>
              <RadioGroup name="type" label="Report Format" options={TYPE_OPTIONS} />
            </Grid>
          </Grid>
        </CardContent>
      </Card>
      <Box mt={2} />
      <Card style={{ height: "100%", overflow: "visible" }}>
        <CardContent sx={{ overflow: "visible" }}>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <FormLabel>Report Header</FormLabel>
              <Box display="flex" justifyContent="space-between">
                <Box flexGrow={1} maxWidth={320}>
                  <HeaderField
                    name="left_header_1"
                    clientProjectName="left_header_1_as_client_project"
                    label="Left Header 1"
                  />
                  <Box mt={1} />
                  <HeaderField
                    name="left_header_2"
                    clientProjectName="left_header_2_as_client_project"
                    label="Left Header 2"
                  />
                </Box>
                <Box display="flex" alignItems="flex-end" flexGrow={1} maxWidth={320}>
                  <Box width="100%">
                    <HeaderField
                      name="center_header"
                      clientProjectName="center_header_as_client_project"
                      label="Center Header"
                      inputProps={{ style: { textAlign: "center" } }}
                    />
                  </Box>
                </Box>
                <Box flexGrow={1} maxWidth={320}>
                  <TextField
                    disabled
                    fullWidth
                    size="small"
                    label="Date Generated"
                    value="Month Day, Year"
                    sx={{ background: "#ECEBEB", borderRadius: "4px" }}
                    inputProps={{ style: { textAlign: "right" } }}
                  />
                  <Box mt={1} />
                  <TextField
                    disabled
                    fullWidth
                    size="small"
                    label="Page Number"
                    value="Page xx of xx"
                    sx={{ background: "#ECEBEB", borderRadius: "4px" }}
                    inputProps={{ style: { textAlign: "right" } }}
                  />
                </Box>
              </Box>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
      <Box mt={2} />
      <Grid item container xs={12} spacing={1}>
        <Grid item xs={6}>
          <FormControl fullWidth component={Content}>
            <FormLabel>Group By</FormLabel>
            <GroupByFields name="groups" disabled />
          </FormControl>
        </Grid>
        <Grid item xs={6}>
          <FormControl fullWidth component={Content}>
            <FormLabel>Filter By</FormLabel>
            <FilterFields name="filters" />
          </FormControl>
        </Grid>
      </Grid>
      <NotificationField />
      {format !== "spec_sheet" &&
        (isFormatPacking ? (
          <>
            <Box mt={2} />
            <Layer1Fields />
            <Box mt={2} />
            <Layer2Fields />
            <Box mt={2} />
            <Layer3Fields />
          </>
        ) : (
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <Card sx={{ height: "100%" }}>
                <CardContent>
                  <FormLabel>Table Display Fields</FormLabel>
                  <ColumnOrderField />
                  <Box mb={3} />
                  <IncludeReportFields
                    component={Checkbox}
                    hideFields={hideFields}
                    displayFilter={true}
                  />
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        ))}
      <Box mt={3} />
      <Button
        variant="contained"
        color="primary"
        type="submit"
        disabled={isSubmitting}
        startIcon={isSubmitting && <CircularProgress size={18} color="inherit" />}
      >
        {isEdit ? "Save" : "Create"}
      </Button>
    </>
  );
};

export default React.memo(FavouriteReportFields);
