import React from "react";
import { useParams } from "react-router-dom";
import moment from "moment/moment";
import { useMutation } from "@apollo/react-hooks";
import TableContainer from "@mui/material/TableContainer";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import LinearProgress from "@mui/material/LinearProgress";
import { serverFormat } from "../../../utils/dates";
import { DELETE_SHIPMENT_MUTATION } from "../../../constants/graphql/mutations";
import { GET_SHIPMENTS_QUERY } from "../../../constants/graphql/queries";
import InboundActions from "./InboundActions";

export default function InboundTable({ list, onEdit }) {
  const { projectId } = useParams();
  const hasDrafts = list.some(item => item.draft);
  const [removeShipment, { loading }] = useMutation(DELETE_SHIPMENT_MUTATION, {
    refetchQueries: [{ query: GET_SHIPMENTS_QUERY, variables: { projectId } }],
  });

  const handleRemoveShipment = id => {
    return removeShipment({ variables: { id } });
  };

  return (
    <>
      <Box height={3}>{loading && <LinearProgress />}</Box>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Shipment ID</TableCell>
              <TableCell>Submitted On</TableCell>
              <TableCell>Submitted By</TableCell>
              {hasDrafts && <TableCell>Status</TableCell>}
              <TableCell align="right">Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {list.map(item => (
              <TableRow key={item.id} sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                <TableCell component="th" scope="row">
                  {item.shipmentId}
                </TableCell>
                <TableCell>{moment(item.createdAt, serverFormat).format("lll")}</TableCell>
                <TableCell>{item.user.email}</TableCell>
                {hasDrafts && (
                  <TableCell>
                    <Box sx={{ color: item.draft ? "red" : "inherit", fontWeight: 500 }}>
                      {item.draft ? "Draft" : "Complete"}
                    </Box>
                  </TableCell>
                )}
                <TableCell align="right">
                  <InboundActions
                    item={item}
                    onEdit={onEdit}
                    onRemove={() => handleRemoveShipment(item.id)}
                  />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
}
