import { useMemo } from "react";
import snakeCase from "lodash/snakeCase";
import useAuth from "src/hooks/useAuth";
import { useCustomProjectFields } from "src/contexts/clients";

export default function useProjectSpecificFields(fields) {
  const { user } = useAuth();
  const displayProjectFields = useCustomProjectFields();

  return useMemo(() => {
    if (!user.company.settings.project_specific_fields || !displayProjectFields) {
      return fields;
    }

    const projectFields = {
      ...displayProjectFields,
      barcode: displayProjectFields.barcode_number
    };

    return fields.map(field => ({
      ...field,
      visible: projectFields[snakeCase(field.id)]
    }));
  }, [fields, displayProjectFields, user.company.settings.project_specific_fields]);
}
