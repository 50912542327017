import React from "react";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import useAuth from "../../hooks/useAuth";

export default function LabelingOptionsModal({ onClose, shipmentId }) {
  const { user } = useAuth();

  return (
    <Dialog open onClose={onClose} maxWidth="xs" fullWidth>
      <DialogTitle>
        <Box position="relative">
          <Typography fontSize={24} fontWeight={600} color="text.secondary" align="center">
            Labeling Options
          </Typography>
          <IconButton
            onClick={onClose}
            size="small"
            sx={{ position: "absolute", right: 0, top: 0 }}
          >
            <CloseIcon />
          </IconButton>
        </Box>
      </DialogTitle>
      <DialogContent>
        <Box pt={1} pb={4}>
          <Typography align="center" gutterBottom>
            Would you like to generate item labels based on the total number of units received or by
            the number of pallets received?
          </Typography>
          <Box display="flex" justifyContent="center" gap={2} mt={3}>
            <Button
              variant="outlined"
              color="primary"
              href={`/shipment_items_pdfs/${shipmentId}?item_lables_count_by=qty&token=${user.token}`}
              target="_blank"
              onClick={onClose}
            >
              Generate by Units
            </Button>
            <Button
              variant="contained"
              color="primary"
              href={`/shipment_items_pdfs/${shipmentId}?item_lables_count_by=rack_location&token=${user.token}`}
              target="_blank"
              onClick={onClose}
              sx={{ "&:hover": { color: "white" } }}
            >
              Generate by Pallets
            </Button>
          </Box>
        </Box>
      </DialogContent>
    </Dialog>
  );
}
