import React from "react";
import Box from "@mui/material/Box";

import { replaceCollection } from "../../../components/TablePro/utils";
import ActionModal from "../../../components/modals/action-modal";
import useMutation from "../../../hooks/useMutation";
import { ASSETS_BULK_UPDATE_MUTATION } from "../../../constants/graphql";
import { useAssetsCollection } from "../../../contexts/assetsCollection";
import { useProjectInventory } from "../../../contexts/projectInventory";
import MultipleDestinationRoomNumberForm from "../../../components/TablePro/fields/assets/MultipleDestinationRoomNumberForm";

export default function MultipleDestinationRoomNumberOrdersModal({ open, toggleModal, entity }) {
  const { setCollection, setLoadingMutation } = useAssetsCollection();
  const [bulkUpdateAssets, { loading }] = useMutation(ASSETS_BULK_UPDATE_MUTATION);
  const { updateInventoryAsset } = useProjectInventory();

  const handleSave = values => {
    setLoadingMutation(true);
    replaceCollection(setCollection, { [entity.id]: values });
    return bulkUpdateAssets({
      variables: { input: { id: entity.id, ...values } },
      onSuccess: ({ assets }) => {
        if (assets) {
          assets.forEach(asset => {
            updateInventoryAsset(asset.id, asset);
          });
        }
        setLoadingMutation(false);
        toggleModal();
      },
    });
  };

  return open ? (
    <ActionModal
      open
      title="Destination Room Number"
      maxWidth={540}
      onClose={toggleModal}
      disableTransition
    >
      <Box mt={1} />
      <MultipleDestinationRoomNumberForm
        loading={loading}
        destinationRoomNumbers={entity.destinationRoomNumbers || []}
        toggleModal={toggleModal}
        onSubmit={handleSave}
      />
    </ActionModal>
  ) : null;
}
