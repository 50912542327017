import React from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";

import labels from "../../constants/displayFields";
import Input from "../../components/form/Input";

export default function FieldsPreReceive({ fullWidth, disabledForm }) {
  const md = fullWidth ? 12 : 4;

  return (
    <Card sx={{ height: "100%", overflow: "visible" }}>
      <CardContent>
        <Box mt={-1} mb={1}>
          <Typography fontWeight={500} fontSize={18}>
            Pre-Receive
          </Typography>
        </Box>
        <Grid container spacing={1}>
          <Grid item xs={12} sm={md}>
            <Input
              type="number"
              name="expectedQuantity"
              label={labels.expected_quantity}
              disabled={disabledForm}
            />
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
}
