const initialLocation = { new: true, sub_qty: 0 };

export const getInitialLocation = (isTempLocationEnabled, tempLocation, qty, shipmentId) => {
  const values =
    isTempLocationEnabled && tempLocation
      ? {
          ...initialLocation,
          warehouse: tempLocation.warehouseId,
          rack_location: [tempLocation.locationName],
        }
      : { ...initialLocation };

  if (qty) {
    values.restock_sub_qty = qty;
  }

  if (shipmentId) {
    values.shipment_id = shipmentId;
  }

  return values;
};

export const getFormattedLocations = (values, isWarehouseRestockModalEnabled) => {
  const getSubQty = location =>
    location.restock_sub_qty ? location.restock_sub_qty + location.sub_qty : location.sub_qty;

  return values.locations.map(location => {
    const formattedLocation = {
      sub_qty: getSubQty(location),
      rack_location: location.rack_location,
      allocated_quantity: location.allocated_quantity ?? 0,
    };

    if (isWarehouseRestockModalEnabled) {
      formattedLocation.warehouse = location.warehouse;

      if (location.mobile_warehouse_location) {
        formattedLocation.mobile_warehouse_location = location.mobile_warehouse_location;
      }
    }

    if (location.shipment_id) {
      formattedLocation.shipment_id = location.shipment_id;
    }

    return formattedLocation;
  });
};

export const getOldRackLocations = locations => {
  return locations.map(v => {
    const location = {
      rack_location: v.rack_location,
      warehouse: v.warehouse,
      sub_qty: v.sub_qty,
    };

    if (v.mobile_warehouse_location) {
      location.mobile_warehouse_location = v.mobile_warehouse_location;
    }

    if (v.shipment_id) {
      location.shipment_id = v.shipment_id;
    }

    return location;
  });
};

export const getRackLocationsChanges = locations => {
  return locations
    .filter(v => v.restock_sub_qty)
    .map(v => {
      const location = {
        sub_qty: v.restock_sub_qty,
        rack_location: v.rack_location,
        warehouse: v.warehouse,
      };

      if (v.mobile_warehouse_location) {
        location.mobile_warehouse_location = v.mobile_warehouse_location;
      }

      if (v.shipment_id) {
        location.shipment_id = v.shipment_id;
      }

      return location;
    });
};
