import React from "react";
import { useFormikContext } from "formik";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Input from "../../form/Input";
import TotalReceivingUnits from "src/components/capture/ReceivingWizard/Shipment/TotalReceivingUnits/TotalReceivingUnits";

export default function ItemShipmentInfoFields({ shipmentItem }) {
  const { values } = useFormikContext();

  if (!shipmentItem || shipmentItem.quantity === 0 || !values.description) {
    return null;
  }

  return (
    <Grid item xs={12}>
      <Card sx={{ overflow: "visible" }}>
        <CardContent>
          <Box mt={-1} mb={1}>
            <Typography fontWeight={500} fontSize={18}>
              Item Shipment Info
            </Typography>
          </Box>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <Input
                type="number"
                name="shipmentItemQuantity"
                label="Quantity Received in This Shipment"
              />
            </Grid>
            <Grid item xs={12}>
              <TotalReceivingUnits name="totalPieces" label="Handling Units for This Item" />
            </Grid>
            <Grid item xs={12}>
              <Input name="itemNotes" label="Optional Item Notes" />
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </Grid>
  );
}
