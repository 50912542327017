import React, { useState } from "react";
import { useFormikContext } from "formik";
import TextField from "@mui/material/TextField";

import useAuth from "../../hooks/useAuth";
import InventoryTransactionMultipleModal from "../../components/modals/InventoryTransactionMultipleModal";

const name = "availableQuantity";

export default function AvailableQuantityInput({
  type,
  display,
  asset,
  disabledForm,
  onRestockInventory,
  component: Component,
}) {
  const { user } = useAuth();
  const [openAvailableQuantity, setOpenAvailableQuantity] = useState(false);
  const { setFieldValue, errors, submitCount, getFieldMeta, getFieldProps } = useFormikContext();
  const meta = getFieldMeta(name);
  const errorMessage = meta.error || errors[name];
  const error = (meta.touched || submitCount > 0) && errorMessage;
  const label = type === "item" ? "Inventory Placement" : user.company.settings.quantity_label;

  const toggleAvailableQuantity = () => {
    if (disabledForm) return;

    setOpenAvailableQuantity(open => !open);
  };

  return (
    <>
      {Component ? (
        <Component onClick={toggleAvailableQuantity} />
      ) : (
        <TextField
          fullWidth
          value={asset[name]}
          size="small"
          label={label}
          error={!!error}
          helperText={error}
          onClick={toggleAvailableQuantity}
          disabled={disabledForm}
          onKeyDown={e => {
            if (e.key === "Enter") {
              toggleAvailableQuantity();
            }
          }}
        />
      )}
      {openAvailableQuantity && (
        <InventoryTransactionMultipleModal
          title="Inventory Placement"
          open={true}
          onClose={toggleAvailableQuantity}
          onSuccess={(data, values) => {
            setFieldValue("quantityReceived", data.asset.quantityReceived);
            toggleAvailableQuantity();
            if (onRestockInventory) {
              onRestockInventory(data, values);
            }
          }}
          asset={asset}
          code={asset.assetId}
          quantityOrdered={display.quantity_ordered}
          quantityReceived={display.quantity_received}
        />
      )}
    </>
  );
}
