import React from "react";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import ListItemText from "@mui/material/ListItemText";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import ButtonBase from "@mui/material/ButtonBase";
import Typography from "@mui/material/Typography";
import orderBy from "lodash/orderBy";
import PopupState, { bindMenu, bindTrigger } from "material-ui-popup-state";

import { useCustomFields } from "../../contexts/customFields";
import useFields from "../../hooks/useFields";
import useLabels from "../../hooks/useLabels";

export default function SortAssets({ bulkProps, order: selected, setOrder }) {
  const [display] = useCustomFields();
  const fields = useFields();
  const l = useLabels();

  const enabledFields = orderBy(
    fields
      .flatMap(v => v.list.map(([id, , label]) => ({ id, label, enabled: display[id] })))
      .filter(v => !["photo", "attachments", "barcode", "barcode_number"].includes(v.id))
      .concat({
        id: "barcode",
        label: l.identifiers.barcode_number,
        enabled: display.barcode_number,
      })
      .filter(v => v.enabled),
    "label",
    "asc"
  );

  const selectedField = selected.split(".")[0];
  const selectedOrder = selected.split(".")[1];
  const selectedItem = enabledFields.find(v => v.id === selectedField);

  const updateOrder = () => {
    const order = selectedOrder === "desc" ? "asc" : "desc";
    setOrder(`${selectedField}.${order}`);
  };

  return (
    <PopupState variant="popover" popupId="demo-popup-menu">
      {popupState => (
        <>
          {bulkProps.label}
          <ButtonBase sx={{ height: 40 }} {...bindTrigger(popupState)}>
            <Typography variant="body1" fontWeight={500}>
              {selectedItem ? selectedItem.label : "Sort By"}
            </Typography>
          </ButtonBase>
          {selectedItem && (
            <ButtonBase sx={{ ml: 0.5 }} onClick={updateOrder}>
              <ArrowDownwardIcon
                sx={{ transform: selectedOrder === "desc" ? undefined : "rotate(180deg)" }}
              />
            </ButtonBase>
          )}
          <Menu {...bindMenu(popupState)}>
            {enabledFields.map(({ id, label }) => (
              <MenuItem
                key={id}
                selected={selectedField === id}
                onClick={() => {
                  setOrder(`${id}.desc`);
                  popupState.close();
                }}
              >
                <ListItemText primary={label} />
              </MenuItem>
            ))}
          </Menu>
        </>
      )}
    </PopupState>
  );
}
