import React from "react";
import { useHistory, useParams } from "react-router-dom";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import ViewSidebarOutlinedIcon from "@mui/icons-material/ViewSidebarOutlined";
import PopupState, { bindMenu, bindTrigger } from "material-ui-popup-state";
import MenuItem from "@material-ui/core/MenuItem";
import MuiMenu from "@material-ui/core/Menu";
import ListItemText from "@material-ui/core/ListItemText";

import { usePermissions } from "../../contexts/permissions";
import { useProjectInventory } from "../../contexts/projectInventory";

export default function Views() {
  const { can } = usePermissions();
  const history = useHistory();
  const params = useParams();
  const currentTab = params.tab;
  const { clientId, inputProjectId: projectId } = useProjectInventory();

  const TABS = [
    {
      value: "assets",
      label: "Table View",
      disabled: !can("assets.read"),
    },
    {
      value: "capture-inventory",
      label: "Split View",
      disabled: !can("features.capture_inventory"),
    },
  ];

  const availableTabs = TABS.filter(tab => !tab.disabled);

  const onChangeTab = tab => {
    if (projectId && projectId !== "all") {
      history.push(`/projects/${projectId}/${tab}`);
    } else if (clientId) {
      history.push(`/clients/${clientId}/${tab}`);
    }
  };

  return (
    <PopupState variant="popover" popupId="demo-popup-menu">
      {popupState => (
        <React.Fragment>
          <MenuItem {...bindTrigger(popupState)}>
            <ViewSidebarOutlinedIcon
              sx={{ transform: "rotate(180deg)" }}
              fontSize="small"
              htmlColor="#546e7a"
            />
            <Box mr={1} />
            <Typography color="textSecondary" variant="body2">
              Views
            </Typography>
          </MenuItem>
          <MuiMenu {...bindMenu(popupState)} sx={{ display: "flex", flexDirection: "column" }}>
            {availableTabs.map(option => (
              <MenuItem
                key={option.value}
                data-id="select-item"
                selected={currentTab === option.value}
                onClick={() => {
                  popupState.close();
                  if (["assets", "capture-inventory"].includes(option.value)) {
                    localStorage.setItem("selectedPage", option.value);
                  }
                  onChangeTab(option.value);
                }}
              >
                <ListItemText primary={option.label} />
              </MenuItem>
            ))}
          </MuiMenu>
        </React.Fragment>
      )}
    </PopupState>
  );
}
