import React, { useState } from "react";
import moment from "moment/moment";
import { useHistory } from "react-router-dom";
import Bpx from "@mui/material/Box";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Link from "@mui/material/Link";

import { serverFormatSeconds } from "../../../../utils/dates";
import getTotalReceivingUnitsLabel from "../Shipment/TotalReceivingUnits/getTotalReceivingUnitsLabel";
import Header from "../Header";
import Progress from "../Progress";
import useTimer from "../../../../hooks/shipment/useTimer";
import useAuth from "../../../../hooks/useAuth";
import LabelingOptionsModal from "../../../modals/LabelingOptionsModal";

export default function Confirmation({ shipment, onClose }) {
  const { user } = useAuth();
  const history = useHistory();
  const [openLabelingModal, setOpenLabelingModal] = useState(false);
  const totalPieces = shipment?.totalPieces || [];
  const start = moment(shipment.startTime, serverFormatSeconds);
  const end = moment(shipment.completeTime, serverFormatSeconds);
  const [minutes, seconds] = useTimer(start, end);

  const toggleLabelingModal = () => {
    setOpenLabelingModal(v => !v);
  };

  const moveToStepOne = () => {
    const pathname = window.location.pathname;
    const params = new URLSearchParams(window.location.search);
    params.set("step", "shipment");
    history.push({ pathname, search: params.toString() });
  };

  return (
    <Bpx display="flex" flexDirection="column" justifyContent="space-between" minHeight="100%">
      <Header title="Confirmation" info={getTotalReceivingUnitsLabel(totalPieces)} />
      <Bpx p={2}>
        <Box mt={3} display="flex" flexDirection="column" alignItems="center">
          <Box
            component="img"
            borderRadius={2}
            width="100%"
            maxWidth={360}
            src="/shipment-confirmation.png"
          />
          <Typography mt={5} textAlign="center">
            Success! You recorded another shipment.
          </Typography>
          <Typography mt={3}>
            You completed in:{" "}
            <b>
              {minutes} min {seconds} seconds
            </b>
          </Typography>
          <Box pt={6}>
            <Link
              sx={{ textDecoration: "none", fontWeight: 500, fontSize: 16 }}
              href={`/shipment_pdfs/${shipment.id}?token=${user.token}`}
              target="_blank"
            >
              Receiving Report PDF Link
            </Link>
          </Box>
          <Box pt={3} pb={2}>
            <Button color="primary" variant="text" onClick={toggleLabelingModal}>
              Item Labels Link
            </Button>
          </Box>
        </Box>
      </Bpx>
      <Box mt={2}>
        <Progress step={2} />
        <Box p={2}>
          <Box display="flex" justifyContent="space-between">
            <Button variant="outlined" color="inherit" onClick={moveToStepOne}>
              Edit Shipment
            </Button>
            <Button onClick={onClose} variant="contained">
              Close
            </Button>
          </Box>
        </Box>
      </Box>
      {openLabelingModal && (
        <LabelingOptionsModal onClose={toggleLabelingModal} shipmentId={shipment.id} />
      )}
    </Bpx>
  );
}
