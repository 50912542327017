import React from "react";
import orderBy from "lodash/orderBy";
import isEmpty from "lodash/isEmpty";
import camelCase from "lodash/camelCase";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";

import Table from "../../components/TablePro/Table";
import { useFullScreen } from "../../contexts/fullScreen";
import useMutation from "../../hooks/useMutation";
import { UPDATE_USER_MUTATION } from "../../constants/graphql";
import useAuth from "../../hooks/useAuth";
import initialSettings from "../../constants/tablePro/warehouse";
import LocationNameLink from "../../components/TablePro/components/warehouse/LocationNameLink";
import EditLocationDetails from "../../components/TablePro/components/warehouse/EditLocationDetails";

const fieldComponents = {
  locationName: {
    component: LocationNameLink,
    input: EditLocationDetails,
    unEditable: false,
  },
  occupied: {
    component: ({ value }) => (value ? "Occupied" : "Empty"),
  },
  dimensions: {
    component: ({ value }) => {
      if (!value?.[0]?.height) return "";
      return `${value[0].height}"`;
    },
  },
};

export default function WarehouseTable({ list }) {
  const { user, setUser } = useAuth();
  const [updateUser] = useMutation(UPDATE_USER_MUTATION);
  const { fullScreen, setFullScreen } = useFullScreen();
  const warehouseTableSettings = React.useMemo(
    () =>
      isEmpty(user.warehouseTableSettings) ||
      user?.warehouseTableSettings?.version !== initialSettings.version
        ? initialSettings
        : user.warehouseTableSettings,
    [user.warehouseTableSettings]
  );
  const entities = orderBy(
    list,
    camelCase(Object.keys(warehouseTableSettings.order)[0]),
    Object.values(warehouseTableSettings.order)[0]
  );
  const fields = React.useMemo(() => {
    return warehouseTableSettings.fields.map(field => ({
      ...field,
      ...(fieldComponents[field.id] || {}),
    }));
  }, [warehouseTableSettings.fields]);

  const changeWarehouseSettings = warehouseTableSettings => {
    setUser({ ...user, warehouseTableSettings });
    return updateUser({
      variables: { warehouseTableSettings },
      onSuccess: data => {
        setUser({ ...user, warehouseTableSettings });
      },
    });
  };

  return list.length === 0 ? (
    <Paper>
      <Typography p={3} align="center" color="textSecondary">
        Empty
      </Typography>
    </Paper>
  ) : (
    <Table
      fullScreen={fullScreen}
      size={warehouseTableSettings.size}
      disabledScroll={false}
      totalCount={list.length}
      order={warehouseTableSettings.order}
      loading={false}
      loadingFields={false}
      fields={fields}
      fieldsProps={undefined}
      entities={entities}
      onLoadMore={() => {}}
      onChangeEntity={() => {}}
      onChangeFields={data =>
        changeWarehouseSettings({
          ...warehouseTableSettings,
          ...data,
        })
      }
      onFillDown={() => {}}
      onCopyPaste={() => {}}
    />
  );
}
