import * as mutations from "./mutations";
import * as queries from "./queries";
import * as subscriptions from "./subscriptions";

export const {
  FETCH_USER,
  FETCH_AUTH_USER_QUERY,
  FETCH_SHORT_USER,
  ASSETS_COLLECTION_QUERY,
  LOOKUPS_QUERY,
  CLIENTS_QUERY,
  PROJECTS_QUERY,
  CLIENTS_TABLE_QUERY,
  PROJECTS_TABLE_QUERY,
  BARCODE_SCANS_QUERY,
  USERS_TABLE_QUERY,
  ASSET_BY_BARCODE_QUERY,
  ASSET_QUERY,
  FAVORITE_REPORTS_QUERY,
  NOTIFICATIONS_QUERY,
  INVENTORY_REQUESTS_QUERY,
  MOVE_MATRICES_QUERY,
  SUBSCRIPTION_QUERY,
  COMPANY_PLANS_QUERY,
  SHOPPING_INVENTORY_REQUEST_QUERY,
  SHOPPING_CART_INVENTORY_REQUEST_QUERY,
  SHOPPING_INVENTORY_REQUESTS_COLLECTION_QUERY,
  FETCH_PROJECT_QUERY,
  FETCH_CLIENT_QUERY,
  CLIENT_USERS,
  COMPANY_USERS_QUERY,
  INVENTORY_TRANSACTIONS_QUERY,
  ASSETS_COLLECTION_TABLE_QUERY,
  ASSET_ATTACHMENTS_QUERY,
  ACCESS_LINKS_QUERY,
  ACCESS_LINK_QUERY,
} = queries;

export const {
  CREATE_IMAGE_MUTATION,
  EXPORT_DONATIONS_REPORT_MUTATION,
  EXPORT_EXCEL_DATA_MUTATION,
  EXPORT_ALT_EXCEL_DATA_MUTATION,
  CREATE_BARCODE_SCAN_MUTATION,
  CREATE_PROJECT_MUTATION,
  CREATE_BARCODE_MUTATION,
  UPDATE_BARCODE_MUTATION,
  DELETE_BARCODE_MUTATION,
  GENERATE_PROJECT_REPORT_MUTATION,
  CREATE_FAVORITE_REPORT_MUTATION,
  UPDATE_FAVORITE_REPORT_MUTATION,
  CREATE_MOVE_MATRIX_MUTATION,
  CREATE_COMPANY_SUBSCRIPTION_MUTATION,
  CANCEL_COMPANY_SUBSCRIPTION_MUTATION,
  SIGN_IN_USER_MUTATION,
  ASSET_MUTATION,
  REMOVE_ASSET_MUTATION,
  INVITE_USER_MUTATION,
  REMOVE_USER_MUTATION,
  UPDATE_USER_PASSWORD_MUTATION,
  UPDATE_USER_PROFILE_MUTATION,
  UPDATE_USER_ACCOUNT_MUTATION,
  ACCEPT_INVITATION_MUTATION,
  UPDATE_IMAGE_MUTATION,
  DELETE_IMAGE_MUTATION,
  ADD_SHOPPING_INVENTORY_ITEM_MUTATION,
  ADD_RESERVED_SHOPPING_INVENTORY_ITEM_MUTATION,
  MAKE_SHOPPING_INVENTORY_REQUEST_MUTATION,
  RESOLVE_INVENTORY_ITEM_MUTATION,
  CHECK_OUT_INVENTORY_ITEM_MUTATION,
  CHECK_IN_INVENTORY_ITEM_MUTATION,
  UPDATE_PROJECT_MUTATION,
  UPDATE_CLIENT_MUTATION,
  UPDATE_COMPANY_PERMISSIONS_MUTATION,
  CHECK_OUT_MUTATION,
  CHECK_IN_MUTATION,
  UPDATE_PROJECT_SETTINGS_MUTATION,
  UPDATE_COMPANY_SETTINGS_MUTATION,
  IMPORT_ASSETS_MUTATION,
  IMPORT_PROCUREMENT_SHEET_MUTATION,
  RESET_USER_PASSWORD_MUTATION,
  FORGET_PASSWORD_REQUEST_MUTATION,
  UPDATE_USER_MUTATION,
  REMOVE_CLIENT_MUTATION,
  EXCLUDE_USER_MUTATION,
  REMOVE_PROJECT_MUTATION,
  ASSETS_BULK_UPDATE_MUTATION,
  ASSETS_BULK_REMOVE_MUTATION,
  ADD_ASSET_ATTACHMENT_MUTATION,
  REMOVE_ASSET_ATTACHMENT_MUTATION,
  CREATE_ASSET_MUTATION,
  TRANSFER_ASSET_MUTATION,
  UPDATE_ASSET_MUTATION,
  ADD_ASSET_IMAGE_MUTATION,
  CREATE_INVENTORY_TRANSACTION_MUTATION,
  BULK_CREATE_ASSET_IMAGES_MUTATION,
  ACCEPT_GUEST_MUTATION,
  CREATE_ACCESS_LINK_MUTATION,
  UPDATE_ACCESS_LINK_MUTATION,
  REMOVE_ACCESS_LINK_MUTATION,
  CREATE_PROJECT_ACCESS_LINK_MUTATION,
  DOWNLOAD_ASSETS_IMAGES_MUTATION,
  ADD_INVENTORY_ITEM_MUTATION
} = mutations;

export const {
  ASSET_MUTATED_SUBSCRIPTION,
  ASSET_DELETED_SUBSCRIPTION,
  NEW_MESSAGE_SUBSCRIPTION,
  MOVE_MATRIX_MUTATION_CREATED_SUBSCRIPTION,
  USER_INVITED_SUBSCRIPTION,
  INVENTORY_ASSET_STOCKED,
} = subscriptions;
