import React from "react";
import { useHistory } from "react-router-dom";
import Bpx from "@mui/material/Box";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import SvgIcon from "@mui/material/SvgIcon";
import { Plus as PlusIcon } from "react-feather";
import Footer from "src/components/capture/ReceivingWizard/Footer/Footer";
import ItemDetails from "src/components/capture/ReceivingWizard/Items/ItemDetails/ItemDetails";
import useQueryParams from "src/hooks/useQueryParams";
import CalculateItemWeight from "src/components/capture/ReceivingWizard/Items/CalculateItemWeight/CalculateItemWeight";
import getTotalReceivingUnitsLabel from "src/components/capture/ReceivingWizard/Shipment/TotalReceivingUnits/getTotalReceivingUnitsLabel";
import ConfirmPalletQuantities from "src/components/capture/ReceivingWizard/Items/ConfirmPalletQuantities/ConfirmPalletQuantities";
import Empty from "./Empty";
import Header from "../Header";
import Item from "./Item";
import ItemLookup from "./ItemLookup/ItemLookup";
import useShipmentActions from "./hooks/useShipmentActions";

export default function Items({ project, shipment }) {
  const history = useHistory();
  const params = useQueryParams();
  const assetId = params.get("assetId");
  const itemLookup = params.get("itemLookup") === "true";
  const shipmentItemId = params.get("shipmentItemId");
  const openItemWeight = params.get("itemWeight") === "true";
  const openPalletQuantities = params.get("palletQuantities") === "true";
  const shipmentItems = shipment?.shipmentItems || [];
  const totalPieces = shipment?.totalPieces || [];
  const isDraft = shipment.draft;
  const { createShipment, createAssetLoading } = useShipmentActions(shipment.id);

  const handleToggle = () => {
    const pathname = window.location.pathname;
    const params = new URLSearchParams(location.search);
    params.delete("assetId");
    params.delete("shipmentItemId");
    params.delete("addShipmentItem");
    params.delete("itemLookup");
    params.delete("rackLocationsChanges");
    history.push({ pathname, search: params.toString() });
  };

  const handleNextStep = () => {
    const pathname = window.location.pathname;
    const params = new URLSearchParams(window.location.search);
    params.set("step", "os&d");
    history.push({ pathname, search: params.toString() });
  };

  const handleContinue = () => {
    if (isDraft) {
      return handleNextStep();
    }

    const pathname = window.location.pathname;
    const params = new URLSearchParams(window.location.search);

    switch (project.billingType) {
      case "Hundredweight":
        params.set("itemWeight", "true");
        break;
      case "By the Pallet": 
        params.set("palletQuantities", "true");
        break;
      default:
        return handleNextStep();
    }

    history.push({ pathname, search: params.toString() });
  };

  const handleBack = () => {
    const pathname = window.location.pathname;
    const params = new URLSearchParams(window.location.search);
    params.set("step", "shipment");
    history.push({ pathname, search: params.toString() });
  };

  const handleSelect = value => {
    const pathname = window.location.pathname;
    const params = new URLSearchParams(location.search);
    params.set("shipmentItemId", value.id);
    params.set("assetId", value.asset.id);
    history.push({ pathname, search: params.toString() });
  };

  const handleAddItem = () => {
    if (project.importedAssets) {
      const pathname = window.location.pathname;
      const params = new URLSearchParams(location.search);
      params.set("itemLookup", "true");
      history.push({ pathname, search: params.toString() });
    } else {
      createShipment();
    }
  };

  return (
    <Bpx display="flex" flexDirection="column" justifyContent="space-between" minHeight="100%">
      <Header title="Item Details" info={getTotalReceivingUnitsLabel(totalPieces)} />
      <Bpx p={2}>
        <Box display="flex" flexDirection="column" alignItems="center">
          {shipmentItems.length === 0 ? (
            <Empty />
          ) : (
            <Box width="100%" minHeight={300}>
              {shipmentItems.map(value => (
                <Item
                  key={value.id}
                  value={value}
                  images={value.asset.images}
                  onClick={() => handleSelect(value)}
                />
              ))}
              {openItemWeight && (
                <CalculateItemWeight shipment={shipment} shipmentItems={shipmentItems} />
              )}
              {openPalletQuantities && (
                <ConfirmPalletQuantities shipment={shipment} shipmentItems={shipmentItems} />
              )}
            </Box>
          )}
          <Box mt={4}>
            <Button
              variant="outlined"
              sx={{ textTransform: "none" }}
              onClick={handleAddItem}
              disabled={createAssetLoading}
              startIcon={
                createAssetLoading ? (
                  <CircularProgress size={22} />
                ) : (
                  <SvgIcon fontSize="small">
                    <PlusIcon />
                  </SvgIcon>
                )
              }
            >
              Add Item
            </Button>
            {assetId && (
              <ItemDetails
                id={assetId}
                project={project}
                shipment={shipment}
                shipmentItem={shipmentItems.find(v => v.id === shipmentItemId)}
                onClose={handleToggle}
              />
            )}
            {itemLookup && (
              <ItemLookup
                createAssetLoading={createAssetLoading}
                onClose={handleToggle}
                onCreateAsset={createShipment}
              />
            )}
          </Box>
        </Box>
      </Bpx>
      <Box mt={2}>
        <Footer step={1} shipment={shipment}>
          <div>
            <Button onClick={handleBack} variant="outlined" sx={{ mr: 1, textTransform: "none" }}>
              Back
            </Button>
            <Button
              type="submit"
              disabled={shipmentItems.length === 0}
              variant="contained"
              sx={{ textTransform: "none" }}
              onClick={handleContinue}
            >
              Next
            </Button>
          </div>
        </Footer>
      </Box>
    </Bpx>
  );
}
