import { useMemo, useCallback } from "react";
import useTempLocationEnabled from "./useTempLocationEnabled";
import useTempLocation from "./useTempLocation";
import { getInitialLocation } from "../utils";
import useQueryParams from "../../../../hooks/useQueryParams";

export default function useInitialValues(asset, warehouseLocations) {
  const isTempLocationEnabled = useTempLocationEnabled();
  const tempLocation = useTempLocation(warehouseLocations);
  const params = useQueryParams();
  const shipmentId = params.get("shipmentId");

  const initialLocation = useMemo(
    () => getInitialLocation(isTempLocationEnabled, tempLocation, undefined, shipmentId),
    [isTempLocationEnabled, tempLocation, shipmentId]
  );

  const getLocations = useCallback(() => {
    if (!asset?.rackLocations?.length) {
      return [initialLocation];
    }

    if (!shipmentId) {
      return asset.rackLocations;
    }

    const hasShipmentLocations = asset.rackLocations.some(
      location => location.shipment_id === shipmentId
    );

    return hasShipmentLocations ? asset.rackLocations : [...asset.rackLocations, initialLocation];
  }, [asset, initialLocation, shipmentId]);

  const initialValues = useMemo(
    () => ({
      id: asset.id,
      code: asset.assetId,
      deletedQuantity: 0,
      locations: getLocations(),
    }),
    [asset, getLocations]
  );

  return initialValues;
}
