import React, { useCallback, useState, useMemo } from "react";
import { useParams } from "react-router-dom";
import omit from "lodash/omit";
import { useFormikContext } from "formik";
import LinearProgress from "@mui/material/LinearProgress";
import Box from "@mui/material/Box";
import snakeCase from "lodash/snakeCase";

import { formatAssetInput } from "../../../../utils/asset";
import useNotification from "../../../../hooks/notification";
import CaptureSkeleton from "../../../../components/CaptureSkeleton";
import CaptureActionsScrubber from "../../../../components/capture/CaptureActionsScrubber";
import TransferModal from "../../../../views/asset/TransferModal";
import { useAssetsCollection } from "../../../../contexts/assetsCollection";
import useProjectSpecificFields from "../../../../hooks/scrubber/useProjectSpecificFields";
import FormFields from "../../../../views/capture/FormFields";
import useTableSettings from "../../../../hooks/scrubber/useTableSettings";
import useTablePro from "../../../../components/TablePro/extensions/useTablePro";

const styles = {
  stickToBottom: {
    width: "100%",
    position: "sticky",
    bottom: "10px",
    left: 0,
    zIndex: 99,
    padding: "0",
    backgroundColor: "transparent",
  },
};

export default function FormPage({
  type,
  displayClient,
  displayProject,
  currentAsset,
  onCloseModal,
  openDuplicatedAsset,
}) {
  const { projectId: navProjectId } = useParams();
  const formProps = useFormikContext();
  const { notifyError, notifySuccess } = useNotification();
  const [openTransfer, setOpenTransfer] = useState(false);
  const {
    projectId,
    loading,
    loadingMutation,
    updateAsset,
    createAsset,
    createImage,
    removeAsset,
    handeRefetch,
    removeAssetsFromCollection,
  } = useAssetsCollection();
  const settings = useTableSettings();
  const { fields: newFields } = useTablePro(settings);
  const defaultFields = useProjectSpecificFields(newFields);
  const display = useMemo(() => {
    const fields = Object.fromEntries(
      defaultFields.map(field => [snakeCase(field.id), field.visible])
    );
    fields.barcode_number = fields.barcode;
    return fields;
  }, [defaultFields]);

  const handleSubmitAction = ({
    variables,
    setSubmitting,
    setStatus,
    setErrors,
    submitAction,
    handleSubmit,
  }) => {
    handleSubmit();
    setSubmitting(true);

    return submitAction({
      variables: { ...variables, input: formatAssetInput(variables.input) },
      onSuccess: () => {
        setSubmitting(false);
        setStatus({ success: true });
      },
      onFailure: errors => {
        setStatus({ success: false });
        setErrors(errors);
        notifyError(errors);
        setSubmitting(false);
      },
    });
  };

  const submitUpdateAsset = ({ values, ...props }) => () => {
    const variables = { id: currentAsset?.id, input: values };

    return handleSubmitAction({ ...props, variables, submitAction: updateAsset });
  };

  const submitCreateAsset = ({ values, ...props }) => async () => {
    await submitUpdateAsset(formProps)({ values, ...props });
    const variables = { projectId, input: {} };
    return handleSubmitAction({ ...props, variables, submitAction: createAsset });
  };

  const submitDuplicateAsset = ({ values, ...props }) => async () => {
    await submitUpdateAsset(formProps)({ values, ...props });
    const excludeProps = ["assetId", "barcode"];
    const variables = {
      projectId: currentAsset.project.id,
      input: { ...omit(values, excludeProps) },
    };
    return handleSubmitAction({ ...props, variables, submitAction: createAsset }).then(response => {
      const newAssetId = response?.data?.createAsset?.asset;
      if (openDuplicatedAsset && newAssetId) {
        openDuplicatedAsset(newAssetId);
      }
      return response;
    });
  };

  const submitRemoveAsset = () => {
    return removeAsset({
      variables: { id: currentAsset?.id },
      onSuccess: ({ asset }) => {
        notifySuccess("Asset was removed");
      },
    });
  };

  const toggleTransfer = useCallback(() => {
    setOpenTransfer(v => !v);
  }, [setOpenTransfer]);

  const handleTransfer = ({ asset, projectId }) => {
    if (navProjectId && navProjectId !== projectId) {
      removeAssetsFromCollection(asset.id);
    }
  };

  return (
    <form
      id="assetForm"
      className="d-flex flex-column flex-grow-1 justify-content-between justify-content-md-start"
      style={{ position: "relative" }}
    >
      {loading && (
        <Box py={1}>
          <LinearProgress className="mt-2" />
        </Box>
      )}
      {currentAsset?.id ? (
        <FormFields
          type={type}
          asset={currentAsset}
          loading={loadingMutation}
          displayClient={displayClient}
          displayProject={displayProject}
          updateAsset={updateAsset}
          createImage={createImage}
          setErrors={formProps.setErrors}
          errors={formProps.errors}
          overrideDisplayFields={display}
        />
      ) : (
        loading && <CaptureSkeleton />
      )}
      <Box mb={2} />
      <Box sx={styles.stickToBottom}>
        <CaptureActionsScrubber
          disabled={formProps.isSubmitting}
          onSave={submitUpdateAsset(formProps)}
          disableSave={!currentAsset?.id}
          onCreate={submitCreateAsset(formProps)}
          onDuplicate={submitDuplicateAsset(formProps)}
          projectId={projectId}
          onCloseModal={onCloseModal}
          onRemove={submitRemoveAsset}
          onTransfer={toggleTransfer}
          handeRefetch={handeRefetch}
        />
        {openTransfer && (
          <TransferModal
            asset={currentAsset}
            onTransfer={handleTransfer}
            onClose={toggleTransfer}
          />
        )}
      </Box>
    </form>
  );
}
