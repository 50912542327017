import { isVaSubdomain } from "../../utils/inventoryRequests";

function getWO({ workOrderNumber, revisionNumber }) {
  return revisionNumber ? `${workOrderNumber}.${revisionNumber}` : workOrderNumber;
}

export function getTitle(item) {
  const isVA = isVaSubdomain();

  return `${item.designer ? item.designer.split(" ")[0] : ""} ${
    isVA ? "Task Order #" : "Order #"
  } ${getWO(item)}`;
}

export function getReservationTitle(item) {
  return `${item.designer ? item.designer.split(" ")[0] : ""} Reservation #${getWO(item)}`;
}

export function getInventoryRequestFields(inventoryRequest) {
  return {
    poNumber: inventoryRequest.poNumber,
    email: inventoryRequest.email,
    ccEmails: inventoryRequest.ccEmails,
    requirements: inventoryRequest.requirements,
    comment: inventoryRequest.comment || "",
    designer: inventoryRequest.designer,
    startDate: inventoryRequest.startDate,
    startTime: inventoryRequest.startTime,
    endDate: inventoryRequest.endDate,
    endTime: inventoryRequest.endTime,
    contactName: inventoryRequest.contactName,
    contactPhoneNumber: inventoryRequest.contactPhoneNumber,
    resources: inventoryRequest.resources,
    street: inventoryRequest.street || "",
    city: inventoryRequest.city || "",
    zipCode: inventoryRequest.zipCode || "",
    location: inventoryRequest.location || "",
    deliveryDate: inventoryRequest.deliveryDate || "",
    disposition: inventoryRequest.disposition || "",
    members: inventoryRequest.members || [],
  };
}
