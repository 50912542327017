import React from "react";
import LinearProgress from "@material-ui/core/LinearProgress";

const style = {
  alignItems: "center",
  display: "flex",
  flexDirection: "column",
  height: "100%",
  justifyContent: "center",
  position: "fixed",
  left: 0,
  top: 0,
  width: "100%",
  zIndex: 2000,
};

export default function SplashScreen() {
  return (
    <div style={style}>
      <div style={{ width: 400 }}>
        <LinearProgress />
      </div>
    </div>
  );
}
