import React from "react";
import { useFormikContext } from "formik";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import PopupState, { bindMenu, bindTrigger } from "material-ui-popup-state";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { useLoading } from "./LoadingProvider";
import useShipmentActions from "../hooks/useShipmentActions";

const styles = {
  container: {
    position: "sticky",
    bottom: "10px",
    width: "100%",
    left: 0,
    zIndex: 99,
    backgroundColor: "white",
  },
  saveButton: {
    marginRight: "1px",
    height: 36,
    borderTopRightRadius: 0,
    borderBottomRightRadius: 0,
  },
  dropdownButton: {
    padding: 0,
    height: 36,
    minWidth: 40,
    borderTopLeftRadius: 0,
    borderBottomLeftRadius: 0,
  },
};

export default function FormActions({ shipmentId, removing, toggleRemoveItem }) {
  const { submitForm, isSubmitting, values } = useFormikContext();
  const { loading } = useLoading();
  const { createShipment, addShipmentItemLoading, createAssetLoading } = useShipmentActions(
    shipmentId
  );
  const isDuplicateLoading = addShipmentItemLoading || createAssetLoading;
  const isDeleteDisabled = removing || loading;
  const isSaveDisabled = loading || isSubmitting || isDuplicateLoading;

  const handleDuplicate = async () => {
    try {
      await submitForm();
      const {
        attachments,
        availableQuantity,
        shipmentItemQuantity,
        assetId,
        barcode,
        palletsReceivedCount,
        expectedQuantity,
        rackLocations,
        itemNotes,
        totalPieces,
        ...input
      } = values;
      createShipment(input);
    } catch (error) {
      console.error("Error duplicating shipment:", error);
    }
  };

  return (
    <Box sx={styles.container}>
      <Grid container spacing={1}>
        <Grid item xs={6}>
          <Button
            fullWidth
            variant="outlined"
            disabled={isDeleteDisabled}
            startIcon={removing && <CircularProgress size={22} />}
            onClick={toggleRemoveItem}
          >
            Delete
          </Button>
        </Grid>
        <Grid item xs={6}>
          <Box display="flex" justifyContent="flex-end" alignItems="center">
            <Button
              fullWidth
              sx={styles.saveButton}
              variant="contained"
              color="primary"
              onClick={submitForm}
              disabled={isSaveDisabled}
              startIcon={isSubmitting && <CircularProgress size={22} />}
            >
              Save
            </Button>
            <Box variant="contained" aria-label="split button">
              <PopupState variant="popover" popupId="demo-popup-menu">
                {popupState => (
                  <React.Fragment>
                    <Button
                      disabled={isSaveDisabled}
                      variant="contained"
                      color="primary"
                      {...bindTrigger(popupState)}
                      sx={styles.dropdownButton}
                    >
                      <KeyboardArrowDownIcon
                        sx={{ transform: popupState.open ? undefined : "rotate(180deg)" }}
                      />
                    </Button>
                    <Menu {...bindMenu(popupState)}>
                      <MenuItem
                        onClick={() => {
                          handleDuplicate();
                          popupState.close();
                        }}
                      >
                        Duplicate Item
                      </MenuItem>
                    </Menu>
                  </React.Fragment>
                )}
              </PopupState>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
}
