import React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Skeleton from "@mui/material/Skeleton";
import VaStatus from "../requests/VaStatus";

export default function Status({ disabled, loading, inventoryRequest, changeStatus }) {
  return (
    <Box display="flex" alignItems="center" gap={1} mb={3}>
      <Typography fontWeight={500}>Status:</Typography>
      {loading ? (
        <Skeleton variant="rounded" width={120} height={36} />
      ) : (
        <VaStatus
          disabled={disabled}
          inventoryRequest={inventoryRequest}
          changeStatus={changeStatus}
        />
      )}
    </Box>
  );
}
