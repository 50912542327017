import React from "react";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Skeleton from "@mui/material/Skeleton";

export default function InboundTableSkeleton() {
  return (
    <Box p={2}>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>
                <Skeleton animation="wave" width={100} />
              </TableCell>
              <TableCell>
                <Skeleton animation="wave" width={120} />
              </TableCell>
              <TableCell>
                <Skeleton animation="wave" width={110} />
              </TableCell>
              <TableCell>
                <Skeleton animation="wave" width={70} />
              </TableCell>
              <TableCell align="right">
                <Skeleton animation="wave" width={80} />
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {[...Array(3)].map((_, index) => (
              <TableRow key={index}>
                <TableCell>
                  <Skeleton animation="wave" width={120} />
                </TableCell>
                <TableCell>
                  <Skeleton animation="wave" width={180} />
                </TableCell>
                <TableCell>
                  <Skeleton animation="wave" width={200} />
                </TableCell>
                <TableCell>
                  <Skeleton animation="wave" width={80} />
                </TableCell>
                <TableCell align="right">
                  <Skeleton animation="wave" width={100} />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
}
