import React, { useCallback, useState } from "react";
import { useHistory } from "react-router-dom";
import Box from "@mui/material/Box";
import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Link from "@mui/material/Link";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import DeleteIcon from "@mui/icons-material/Delete";
import ChecklistIcon from "@mui/icons-material/Checklist";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import IosShareIcon from "@mui/icons-material/IosShare";
import ReceiptLongIcon from "@mui/icons-material/ReceiptLong";
import PopupState, { bindMenu, bindTrigger } from "material-ui-popup-state";

import useAuth from "../../../../hooks/useAuth";
import useQuery from "../../../../hooks/useQuery";
import useMutation from "../../../../hooks/useMutation";
import { DELETE_INVENTORY_REQUEST_MUTATION } from "../../../../constants/graphql/mutations";
import AttachmentModal from "../AttachmentModal";
import InventoryRequestModal from "../InventoryRequestModal";
import ConfirmDeleteModal from "../../../../components/images/ConfirmDeleteModal";
import ShareModal from "../share/ShareModal";
import ManualLocationCheckout from "./ManualLocationCheckout";
import useNotification from "src/hooks/notification";
import { isVaSubdomain } from "../../../../utils/inventoryRequests";

export default function OrderActions({
  inventoryRequest,
  reFetch,
  updateInventoryRequest,
  removeInventoryItem,
  changeAllInventoryItems,
  disabledVaStatus,
}) {
  const { notifyError, notifySuccess } = useNotification();
  const [removing, setRemoving] = useState(false);
  const history = useHistory();
  const query = useQuery();
  const { user } = useAuth();
  const [deleteInventoryRequest] = useMutation(DELETE_INVENTORY_REQUEST_MUTATION);
  const [attachment, setAttachment] = useState(false);
  const [removeItem, setRemoveItem] = useState(false);
  const [manualLocation, setManualLocation] = useState(false);
  const [openShareModal, setOpenShareModal] = useState(false);
  const isAdmin = user.role === "admin";

  const toggleManualLocation = () => {
    setManualLocation(v => !v);
  };

  const isVA = isVaSubdomain();

  const toggleOpenModal = useCallback(() => {
    const searchParams = new URLSearchParams(window.location.search);
    if (query.get("inventoryRequestId")) {
      searchParams.delete("inventoryRequestId");
      history.push({ search: searchParams.toString() });
    } else {
      searchParams.set("inventoryRequestId", inventoryRequest.id);
      history.push({ search: searchParams.toString() });
    }
  }, [query, history]);

  const toggleAttachmentModal = useCallback(() => {
    setAttachment(v => !v);
  }, [setAttachment]);

  const toggleRemoveModal = useCallback(() => {
    setRemoveItem(v => !v);
  }, [setRemoveItem]);

  const toggleShareModal = useCallback(() => {
    setOpenShareModal(v => !v);
  }, []);

  const handleDelete = useCallback(() => {
    setRemoving(true);
    deleteInventoryRequest({
      variables: { id: inventoryRequest.id },
      onSuccess: () => {
        if (reFetch) {
          Promise.resolve(reFetch())
            .then(() => {
              notifySuccess("Order successfully deleted!");
              setRemoving(false);
            })
            .catch(error => {
              notifyError("An error occurred while refreshing data.");
              setRemoving(false);
            });
        } else {
          console.warn("reFetch is undefined");
          notifySuccess("Order successfully deleted!");
          setRemoving(false);
        }
      },
      onFailure: data => {
        notifyError(data.submit || "An error occurred while deleting.");
        setRemoving(false);
      },
    });
  }, [deleteInventoryRequest, inventoryRequest, reFetch, notifySuccess, notifyError]);

  const handleUpdateInventoryItem = useCallback(
    (id, item) => {
      return updateInventoryRequest(inventoryRequest.id, {
        ...inventoryRequest,
        inventoryItems: inventoryRequest.inventoryItems.map(v =>
          v.id === id
            ? {
                ...v,
                ...item,
              }
            : v
        ),
      });
    },
    [updateInventoryRequest, inventoryRequest]
  );

  return (
    <Box display="flex" flexWrap="wrap" justifyContent="flex-end">
      {window.location.pathname.includes("/inventory") &&
        user.company.settings.manual_location_checkout && (
          <Tooltip title="Order Picking">
            <IconButton color="inherit" onClick={toggleManualLocation}>
              <ChecklistIcon />
            </IconButton>
          </Tooltip>
        )}

      <Tooltip title="Edit Order">
        <IconButton onClick={toggleOpenModal}>
          <EditOutlinedIcon color="action" />
        </IconButton>
      </Tooltip>

      <PopupState variant="popover" popupId="popup-menu">
        {popupState => (
          <React.Fragment>
            <Tooltip title="More Options">
              <IconButton {...bindTrigger(popupState)}>
                <MoreVertIcon color="action" />
              </IconButton>
            </Tooltip>
            <Menu {...bindMenu(popupState)}>
              <MenuItem
                target="_blank"
                component={Link}
                href={`/inventory_request_pdfs/${inventoryRequest.id}?token=${user.token}`}
                onClick={() => popupState.close()}
              >
                <ListItemIcon>
                  <ReceiptLongIcon />
                </ListItemIcon>
                <ListItemText primary="PDF Report" />
              </MenuItem>

              <MenuItem
                onClick={() => {
                  toggleShareModal();
                  popupState.close();
                }}
              >
                <ListItemIcon>
                  <IosShareIcon />
                </ListItemIcon>
                <ListItemText primary={isVA ? "Share Task Order" : "Share Order"} />
              </MenuItem>

              {isAdmin && (
                <MenuItem
                  onClick={() => {
                    toggleRemoveModal();
                    popupState.close();
                  }}
                >
                  <ListItemIcon>
                    <DeleteIcon />
                  </ListItemIcon>
                  <ListItemText primary={isVA ? "Delete Task Order" : "Delete Order"} />
                </MenuItem>
              )}
            </Menu>
          </React.Fragment>
        )}
      </PopupState>

      {attachment && (
        <AttachmentModal inventoryRequestId={inventoryRequest.id} onClose={toggleAttachmentModal} />
      )}
      {openShareModal && (
        <ShareModal inventoryRequest={inventoryRequest} onClose={toggleShareModal} />
      )}
      {query.get("inventoryRequestId") === inventoryRequest.id && (
        <InventoryRequestModal
          id={inventoryRequest.id}
          updateInventoryRequest={updateInventoryRequest}
          changeAllInventoryItems={changeAllInventoryItems}
          updateInventoryItem={handleUpdateInventoryItem}
          removeInventoryItem={removeInventoryItem}
          onClose={toggleOpenModal}
          reFetch={reFetch}
          disabledVaStatus={disabledVaStatus}
        />
      )}
      {removeItem && (
        <ConfirmDeleteModal
          title="Delete Order"
          descr="Are you sure you want to delete this order?"
          onClose={() => setRemoveItem(false)}
          onDelete={handleDelete}
        />
      )}
      {manualLocation && (
        <ManualLocationCheckout
          inventoryRequestId={inventoryRequest.id}
          onClose={toggleManualLocation}
        />
      )}
    </Box>
  );
}
