import React, { useState, useEffect } from "react";
import clsx from "clsx";
import isEmpty from "lodash/isEmpty";
import { FixedSizeList as SizeList } from "react-window";
import InfiniteLoader from "react-window-infinite-loader";
import LinearProgress from "@mui/material/LinearProgress";

import useSelectableCells from "../extensions/useSelectableCells";
import useFillDownCells from "../extensions/useFillDownCells";
import useNotification from "../../../hooks/notification";
import { useInfinityScroll } from "../../../utils/infinityScroll";
import TableRow, { isEqualRow } from "./TableRow";
import { nearestEditableCoords, parseCopyPaste, parseFillDown } from "../utils";
import { MIN_HEIGHT } from "../TableHeader";
import {
  useNavigationEffect,
  useOnCopyPasteEffect,
  useOnEscEffect,
  useOnReFocuse,
  useOnShiftTabEffect,
  useOnTabEffect,
} from "../../../utils/keyEffects";
import { fieldAttrName } from "../../../utils/caller";

const TableRowMemo = React.memo(
  ({ style, index, data: props }) => <TableRow {...props} style={style} rowIndex={index} />,
  isEqualRow
);

const TableBody = ({
  size,
  totalCount,
  loading,
  fields,
  fieldsProps,
  entities,
  onLoadMore,
  onChangeEntity,
  rowsCount,
  height,
  innerRef,
  InnerElementType,
  disabledScroll,
  fieldsWidth,
  onFillDown,
  onCopyPaste,
}) => {
  const [noselect, setNoselect] = useState(false);
  const { notifyError } = useNotification();
  const defaultSelectedCoords = nearestEditableCoords(fields);
  const [endCoords, setEndCoords] = useState([]);
  const [copyDiapason, setCopyDiapason] = useState([]);
  const [cutMode, setCutMode] = useState(false);
  const [editCoords, setEditCoords] = useState([]);

  const {
    selectedCoords,
    setSelectedCoords,
    prevSelectedCell,
    prevSelectedRow,
    nextSelectedCell,
    nextSelectedRow,
  } = useSelectableCells(defaultSelectedCoords);

  const rootCoords = selectedCoords;

  const handleOnFillDown = fillDownDiapason => {
    const variables = parseFillDown({
      fillDownDiapason,
      collection: entities,
      fields,
    });

    onFillDown(variables);
  };

  const { fillDownElementProps, fillDownCellProps, fillDownMode, fillDownReset } = useFillDownCells(
    {
      onFillDown: handleOnFillDown,
      rootCoords,
      endCoords,
      setEndCoords,
    }
  );

  const isNotEditState = isEmpty(editCoords);

  const selectCopyDiapason = ({ cutMode = false } = {}) => {
    const finalDiapason = isEmpty(endCoords) ? rootCoords : endCoords;

    if (isNotEditState) {
      setCutMode(cutMode);
      setCopyDiapason([rootCoords, finalDiapason]);
      fillDownReset();
    } else {
      setCopyDiapason([]);
    }
  };

  const handlePaste = async () => {
    const isMulti =
      copyDiapason[0][0] !== copyDiapason[1][0] || copyDiapason[0][1] !== copyDiapason[1][1];

    const finalCoords = isEmpty(endCoords) ? rootCoords : endCoords;
    if (isEmpty(copyDiapason)) {
      const text = await navigator.clipboard.readText();
      const id = entities[rootCoords[0]].id;
      const pasteKey = fieldAttrName(fields[rootCoords[1]]);
      onCopyPaste({ [id]: { [pasteKey]: text } });
      return;
    }

    try {
      let pasteDiapason;
      if (isMulti) {
        const isVertical = copyDiapason[0][1] === copyDiapason[1][1];
        if (isVertical) {
          pasteDiapason = [rootCoords, [copyDiapason[1][0], finalCoords[1]]];
        } else {
          pasteDiapason = [rootCoords, finalCoords];
        }
      } else {
        pasteDiapason = [rootCoords, finalCoords];
      }
      const variables = parseCopyPaste({
        collection: entities,
        fields,
        copyDiapason,
        cutMode,
        pasteDiaposon: pasteDiapason,
      });
      onCopyPaste(variables);
      setCopyDiapason([]);
      setSelectedCoords(rootCoords);
      setEndCoords(finalCoords);
    } catch (error) {
      notifyError(error);
      setSelectedCoords([]);
      setEndCoords([]);
    }
  };

  const handleNextSelectedCell = () => {
    fillDownReset();
    nextSelectedCell(fields, selectedCoords[0], selectedCoords[1]);
    const activeFields = fields.filter(fieldItem => !fieldItem.inactive && fieldItem.visible);
    const nextEditableIndex = fields.findIndex(
      (fieldItem, index) => index > selectedCoords[1] && !fieldItem.inactive && fieldItem.visible
    );
    const dataField =
      nextEditableIndex >= 0
        ? `${fields[nextEditableIndex].id}-${entities[selectedCoords[0]].id}`
        : `${activeFields[1].id}-${entities[selectedCoords[0] + 1].id}`;

    const el = document.querySelector(`[data-field=${dataField}]`);
    if (el) {
      el.scrollIntoView({ behavior: "smooth", block: "nearest", inline: "end" });
    }
  };

  const handlePrevSelectedCell = () => {
    fillDownReset();
    prevSelectedCell(fields, selectedCoords[0], selectedCoords[1]);
    const activeFields = fields.filter(fieldItem => !fieldItem.inactive && fieldItem.visible);
    const currentEditableIndex = activeFields.findIndex(
      (fieldItem, index) => fieldItem.id === fields[selectedCoords[1]].id
    );
    const prevEditableIndex = currentEditableIndex - 1;
    const dataField =
      prevEditableIndex >= 1
        ? `${activeFields[prevEditableIndex].id}-${entities[selectedCoords[0]].id}`
        : `${activeFields[activeFields.length - 1].id}-${entities[selectedCoords[0] - 1].id}`;

    const el = document.querySelector(`[data-field=${dataField}]`);
    if (el) {
      el.scrollIntoView({ behavior: "smooth", block: "nearest", inline: "center" });
    }
  };

  const handleNextSelectedRow = () => {
    fillDownReset();
    nextSelectedRow(selectedCoords[0], selectedCoords[1]);
  };

  const handlePrevSelectedRow = () => {
    fillDownReset();
    prevSelectedRow(selectedCoords[0], selectedCoords[1]);
  };

  const navigationEvents = {
    left: handlePrevSelectedCell,
    right: handleNextSelectedCell,
    down: handleNextSelectedRow,
    up: handlePrevSelectedRow,
  };

  const modifyEvents = {
    onCopy: () => selectCopyDiapason(),
    onCut: () => selectCopyDiapason({ cutMode: true }),
    onPaste: handlePaste,
  };

  const handleCleanSelect = () => {
    setCopyDiapason([]);

    if (isNotEditState) {
      fillDownReset();
      setSelectedCoords([]);
    }
  };

  useOnEscEffect(innerRef, handleCleanSelect);
  useOnReFocuse(innerRef, handleCleanSelect);
  useNavigationEffect(innerRef, isNotEditState ? navigationEvents : {});
  useOnCopyPasteEffect(innerRef, isNotEditState ? modifyEvents : {});
  useOnShiftTabEffect(innerRef, isNotEditState ? handlePrevSelectedCell : null);
  useOnTabEffect(innerRef, isNotEditState ? handleNextSelectedCell : null);

  const { itemCount, loadMoreItems, isItemLoaded } = useInfinityScroll({
    entities,
    totalCount,
    rowsCount,
    onLoadMore,
    loading,
  });

  const getSelectedEntities = rows => {
    return rows.map(index => entities[index]);
  };

  const rowProps = {
    size,
    entities,
    onChange: onChangeEntity,
    fields,
    fieldsProps,
    fieldsWidth,

    fillDownElementProps,
    fillDownCellProps,

    setEndCoords,
    setEditCoords,
    setSelectedCoords,

    nextCell: nextSelectedCell,
    prevCell: prevSelectedCell,
    nextRow: nextSelectedRow,
    prevRow: prevSelectedRow,

    fillDownMode,
    editCoords,
    rootCoords,
    endCoords,
    copyDiapason,
    getSelectedEntities: getSelectedEntities,
  };

  useEffect(() => {
    if (fillDownMode) {
      setNoselect(true);
    } else {
      setNoselect(false);
      const selection = window.getSelection();
      if (selection) {
        selection.removeAllRanges();
      }
    }
  }, [fillDownMode]);

  return (
    <div className={clsx({ noselect })} ref={innerRef}>
      <div style={{ minHeight: 4 }}>{loading && <LinearProgress />}</div>
      <InfiniteLoader
        isItemLoaded={isItemLoaded}
        itemCount={itemCount}
        loadMoreItems={loadMoreItems}
      >
        {({ onItemsRendered, ref }) => (
          <SizeList
            className={clsx({ "no-scrollbars": disabledScroll })}
            onItemsRendered={onItemsRendered}
            ref={ref}
            height={height}
            itemSize={Math.ceil((height - MIN_HEIGHT - 1) / rowsCount)}
            itemData={rowProps}
            itemCount={itemCount}
            innerElementType={InnerElementType}
            width="100%"
          >
            {TableRowMemo}
          </SizeList>
        )}
      </InfiniteLoader>
    </div>
  );
};

export default TableBody;
