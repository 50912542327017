import React, {useState} from "react";
import {useHistory} from "react-router-dom";
import LinearProgress from "@mui/material/LinearProgress";
import Box from "@mui/material/Box";

import ImageModal from "../../../components/images/ImageModal";
import useNotification from "../../../hooks/notification";
import {useAssetsCollection} from "../../../contexts/assetsCollection";
import useQuery from "../../../hooks/useQuery";
import ImageDND from "./ImageDND";
import ImagesSlider from "./ImagesSlider";
import {sortImages} from "../../../utils/images";

export default function Images({ asset: defaultAsset }) {
  const query = useQuery();
  const history = useHistory();
  const { collection, updateImage, bulkCreateImages, removeImage } = useAssetsCollection();
  const asset = collection.find(v => v.id === defaultAsset.id);

  const { notifyError } = useNotification();
  const [loading, setLoading] = useState(false);
  const list = sortImages(asset?.images);
  const open = query.get("image") === "true";
  const defaultIndex = list.findIndex(v => v.default);
  const [index, setIndex] = useState(defaultIndex === -1 ? 0 : defaultIndex);

  const handleOpen = () => {
    if (list.length > 0) {
      history.push({
        pathname: window.location.pathname,
        search: `?image=true`,
      });
    }
  };

  const handleClose = () => {
    history.push({
      pathname: window.location.pathname,
      search: "",
    });
  };

  const handleBulkCreateImages = ({ variables, onSuccess, onFailure }) => {
    bulkCreateImages({
      variables: { ...variables, assetId: asset?.id },
      onSuccess,
      onFailure,
    });
  };

  const handleUpload = images => {
    setLoading(true);
    handleBulkCreateImages({
      variables: { assetId: asset.id, images },
      onSuccess: () => {
        setLoading(false);
      },
      onFailure: errors => {
        notifyError(errors);
        setLoading(false);
      },
    });
  };

  return (
    <>
      <Box position="relative" onClick={handleOpen}
        sx={{
          width: { xs: '90%', sm: '50%', md: '100%' },  // Adjusts image frame width based on screen size
          margin: '0 auto',
        }}
      >
        {list.length === 0 && (
          <>
            {loading && <LinearProgress />}
            <ImageDND onDrop={handleUpload} />
          </>
        )}
        <ImagesSlider list={list} index={index} onChangeIndex={setIndex} />
      </Box>
      {open && list[index] && (
        <ImageModal
          open
          closeOnSave={true}
          selectedId={list[index].id}
          asset={asset}
          images={list}
          onClose={handleClose}
          onRemove={removeImage}
          onUpdate={updateImage}
          onCreate={handleBulkCreateImages}
        />
      )}
    </>
  );
}
