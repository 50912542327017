import {useMemo} from "react";
import ASSETS_TABLE_PRO, {multipleDestinationRoomNumber} from "src/constants/tablePro/assets";
import useAuth from "src/hooks/useAuth";
import {useProjectInventory} from "src/contexts/projectInventory";
import useLabels from "src/hooks/useLabels";

export default function useTableSettings() {
  const { user } = useAuth();
  const { clientId } = useProjectInventory();
  const l = useLabels();
  const { settings: companySettings } = user.company;

  const dynamicLabels = {
    availableQuantity: l.warehouseInfo.available_quantity,
    serialNumber: l.identifiers.serial_number,
    capitalAssetNumber: l.identifiers.capital_asset_number,
    tssNumber: l.identifiers.tss_number,
    descriptionComments: l.descriptiveInfo.description_comments,
    model: l.descriptiveInfo.model,
    dataPortQty: l.otherInfo.data_port_qty,
    bms: l.otherInfo.bms,
    otherUtility: l.otherInfo.other_utility,
    moveNotes: l.otherInfo.move_notes,
    barcode: l.identifiers.barcode_number,
  };

  return useMemo(() => {
    const initialFields = ASSETS_TABLE_PRO.fields
      .map(v => {
        if (dynamicLabels[v.id]) {
          return { ...v, label: dynamicLabels[v.id] };
        }
        return v;
      })
      .map(v =>
        v.id === "destinationRoomNumber" && companySettings.multi_destination_room_number
          ? { ...v, ...multipleDestinationRoomNumber }
          : v
      );

    return {
      key: "assetsTableSettings",
      default: {
        ...ASSETS_TABLE_PRO,
        fields: initialFields,
      },
    };
  }, [clientId, user.company.settings.quantity_label]);
}
