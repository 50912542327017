import useCaptureUserFields from "src/hooks/useCaptureFields";
import { useCustomProjectFields } from "src/contexts/clients";
import useAuth from "src/hooks/useAuth";

export default function useCaptureFields(settings) {
  const { user } = useAuth();
  const displayUserFields = useCaptureUserFields();
  const displayProjectFields = useCustomProjectFields(settings);
  const { company } = user;

  return user.company.settings.project_specific_fields
    ? displayProjectFields || company.fieldSettings
    : displayUserFields;
}
