import React from "react";
import Box from "@mui/material/Box";
import MenuItem from "@mui/material/MenuItem";
import Checkbox from "@mui/material/Checkbox";

const heightOptions = [
  { label: 'Below 48"', type: "below", value: 48 },
  { label: 'Over 60"', type: "above", value: 60 },
  { label: 'Over 72"', type: "above", value: 72 },
  { label: 'Over 80"', type: "above", value: 80 },
];

export default function LocationHeight({ height, setHeight }) {
  const handleSelect = (type, value) => {
    if (height?.type === type && height?.value === value) {
      setHeight(null);
    } else {
      setHeight({ type, value });
    }
  };

  return (
    <Box>
      {heightOptions.map(({ label, type, value }) => (
        <MenuItem
          key={label}
          sx={{ fontSize: 14 }}
          onClick={() => handleSelect(type, value)}
        >
          <Box my={-1} ml={-1}>
            <Checkbox
              checked={height?.type === type && height?.value === value}
              value=""
            />
          </Box>
          {label}
        </MenuItem>
      ))}
    </Box>
  );
} 