import React, { useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { useQuery } from "@apollo/react-hooks";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import SearchIcon from "@mui/icons-material/Search";
import IconButton from "@mui/material/IconButton";
import { GET_SHIPMENTS_QUERY } from "../../../constants/graphql/queries";
import ReceivingWizardModal from "../../../components/capture/ReceivingWizard/ReceivingWizardModal";
import useQueryParams from "../../../hooks/useQueryParams";
import InboundTable from "./InboundTable";
import InboundTableSkeleton from "./InboundTableSkeleton";
import InboundNoResults from "./InboundNoResults";
import { useClients } from "../../../contexts/clients";

export default function Inbound() {
  const params = useQueryParams();
  const history = useHistory();
  const { projectId } = useParams();
  const { currentClient } = useClients();
  const [searchInput, setSearchInput] = useState("");
  const [variables, setVariables] = useState({ projectId, search: "" });
  const { data: { shipments = [] } = {}, loading } = useQuery(GET_SHIPMENTS_QUERY, {
    variables,
  });

  const handleSearch = () => {
    setVariables({ projectId, search: searchInput });
  };

  const handleKeyPress = e => {
    if (e.key === "Enter") {
      handleSearch();
    }
  };

  const handleSearchAll = () => {
    setVariables(v => ({ clientId: currentClient.id, search: v.search }));
  };

  const handleToggle = shipment => {
    const pathname = window.location.pathname;
    const params = new URLSearchParams(window.location.search);
    if (params.get("receiving")) {
      history.push({ pathname, search: "" });
    } else {
      params.set("step", "shipment");
      params.set("receiving", "true");
      params.set("editShipment", "true");
      params.set("shipmentId", shipment.shipmentId);
      params.set("id", shipment.id);
      history.push({ pathname, search: params.toString() });
    }
  };

  return (
    <Paper>
      <Box p={2} display="flex" justifyContent="space-between" alignItems="center">
        <Typography fontWeight={500}>Inbound Shipments</Typography>
        <Box display="flex" alignItems="center">
          <TextField
            value={searchInput}
            onChange={e => setSearchInput(e.target.value)}
            onKeyPress={handleKeyPress}
            size="small"
            placeholder="Search"
            InputProps={{
              endAdornment: (
                <IconButton onClick={handleSearch} size="small">
                  <SearchIcon color="action" />
                </IconButton>
              ),
            }}
          />
        </Box>
      </Box>
      {loading ? (
        <InboundTableSkeleton />
      ) : variables.projectId && variables.search && shipments.length === 0 ? (
        <InboundNoResults onSearchAll={handleSearchAll} />
      ) : shipments.length === 0 ? (
        <Box p={2} pb={5} textAlign="center">
          <Typography color="text.secondary">No results found</Typography>
        </Box>
      ) : (
        <Box p={2}>
          <InboundTable list={shipments} onEdit={handleToggle} />
        </Box>
      )}
      {params.get("receiving") && <ReceivingWizardModal onToggle={handleToggle} />}
    </Paper>
  );
}
