export function getHolidayDates(blackoutDates = {}) {
  const { holidays = [] } = blackoutDates;
  return holidays;
}

export function getRestrictedDays(blackoutDates = {}) {
  const { restricted_days = [], holidays = [] } = blackoutDates;
  return [...restricted_days, ...holidays];
}

export function isDateRestricted(blackoutDates, selectedDate) {
  const restrictedDays = getRestrictedDays(blackoutDates);
  return restrictedDays.some(day => day.date === selectedDate);
}
