import React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import useAuth from "src/hooks/useAuth";
import { getTitle, getReservationTitle } from "../utils";

export default function Header({ item, type = "order" }) {
  const { user } = useAuth();

  return (
    <Box display="flex" alignItems="center" gap={1}>
      <Typography color="textPrimary" fontSize={20} fontWeight={500}>
        {type === "reservation" ? getReservationTitle(item) : getTitle(item)}
      </Typography>
      <Button
        variant="outlined"
        size="small"
        href={`/inventory_request_pdfs/${item.id}?token=${user.token}`}
        target="_blank"
      >
        PDF Link
      </Button>
    </Box>
  );
}
