import React, {useEffect} from "react";
import {useQuery} from "@apollo/react-hooks";
import sortBy from "lodash/sortBy";
import {Form, Formik} from "formik";
import LinearProgress from "@mui/material/LinearProgress";

import useAuth from "../../hooks/useAuth";
import Autocomplete from "../../components/form/Autocomplete";
import {PROJECTS_QUERY} from "../../constants/graphql";

export default function ProjectFilter({ onSelectProject, projectId, size }) {
  const { user } = useAuth();
  const { settings } = user.company;
  const { data: { projects = [] } = {}, refetch, loading } = useQuery(PROJECTS_QUERY);
  const projectList = sortBy(projects, "name");

  useEffect(() => {
    refetch();
  }, []);

  if (loading) {
    return <LinearProgress className="mt-2" />;
  }

  const formikOnSubmit = (values, { setErrors, setStatus, setSubmitting }) => {};

  let projectsOptions = projectList.map(project => ({
    label: project.name,
    value: project.id,
  }));

  projectsOptions = [{ label: `All ${settings.filter_field3}`, value: "" }, ...projectsOptions];

  return (
    <Formik initialValues={{ projectId }} enableReinitialize onSubmit={formikOnSubmit}>
      <Form>
        <Autocomplete
          size={size}
          placeholder={`Choose a ${settings.filter_field2}`}
          name={"projectId"}
          onChange={projectItem => projectItem && onSelectProject(projectItem.value)}
          options={projectsOptions}
        />
      </Form>
    </Formik>
  );
}
