import React, { useState } from "react";
import { useFormikContext } from "formik";
import TextField from "@mui/material/TextField";

import TotalReceivingUnitsModal from "./TotalReceivingUnitsModal";
import getTotalReceivingUnitsLabel from "./getTotalReceivingUnitsLabel";

export default function TotalReceivingUnits({ name, label }) {
  const [open, setOpen] = useState(false);
  const { values } = useFormikContext();
  const { errors, submitCount, getFieldMeta } = useFormikContext();
  const meta = getFieldMeta(name);
  const errorMessage = meta.error || errors[name];
  const error = (meta.touched || submitCount > 0) && errorMessage;

  const handleToggle = () => {
    setOpen(v => !v);
  };

  return (
    <>
      <TextField
        fullWidth
        error={!!error}
        helperText={error}
        size="small"
        label={label}
        value={getTotalReceivingUnitsLabel(
          Array.isArray(values.totalPieces) ? values.totalPieces : []
        )}
        onClick={handleToggle}
      />
      {open && <TotalReceivingUnitsModal onClose={handleToggle} />}
    </>
  );
}
