import React, { useMemo } from "react";
import { Formik } from "formik";
import Box from "@mui/material/Box";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import DialogContent from "@mui/material/DialogContent";
import { useQuery } from "@apollo/react-hooks";
import CircularProgress from "@mui/material/CircularProgress";

import { CaptureProvider } from "../../../../../contexts/capture";
import { fetchAssetValues } from "../../../../../utils/asset";
import { AssetsProvider } from "../../../../../contexts/assets";
import { ClientsProvider } from "../../../../../contexts/clients";
import CollectionProvider from "../../../../../views/capture/CollectionProvider";
import {
  ASSET_QUERY,
  REMOVE_ASSET_MUTATION,
  UPDATE_ASSET_MUTATION,
} from "../../../../../constants/graphql";
import { DELETE_SHIPMENT_ITEMS_MUTATION } from "../../../../../constants/graphql/mutations";
import { GET_SHIPMENT_QUERY } from "../../../../../constants/graphql/queries";
import { UPDATE_SHIPMENT_ITEMS_MUTATION } from "../../../../../constants/graphql/mutations";

import useMutation from "../../../../../hooks/useMutation";
import { useCustomFields as useCustomProjectFields } from "../../../../../contexts/customFields";
import ConfirmDeleteModal from "../../../../../components/images/ConfirmDeleteModal";
import useQueryParams from "../../../../../hooks/useQueryParams";
import { useItemSchema } from "../useItemSchema";
import FormPage from "./FormPage";

export default function ItemDetails({ id, project, shipment, shipmentItem, reFetch, onClose }) {
  const params = useQueryParams();
  const shipmentId = params.get("id");
  const addShipmentItem = params.get("addShipmentItem") === "true";
  const shipmentItemId = params.get("shipmentItemId");
  const [openRemoveItem, setOpenRemoveItem] = React.useState(false);
  const [display] = useCustomProjectFields();
  const isDraft = shipment.draft;
  const { data: { asset = {} } = {}, loading } = useQuery(ASSET_QUERY, {
    variables: { id },
  });

  const [updateAsset] = useMutation(UPDATE_ASSET_MUTATION);
  const [removeAsset, { loading: removingAsset }] = useMutation(REMOVE_ASSET_MUTATION, {
    refetchQueries: [{ query: GET_SHIPMENT_QUERY, variables: { id: shipmentId } }],
  });
  const [removeShipmentItem, { loading: removingShipmentItem }] = useMutation(
    DELETE_SHIPMENT_ITEMS_MUTATION,
    {
      refetchQueries: [{ query: GET_SHIPMENT_QUERY, variables: { id: shipmentId } }],
    }
  );
  const [updateShipmentItem] = useMutation(UPDATE_SHIPMENT_ITEMS_MUTATION, {
    refetchQueries: [{ query: GET_SHIPMENT_QUERY, variables: { id: shipmentId } }],
  });
  const schema = useItemSchema(display, isDraft);

  const currentShipmentItem = shipment?.shipmentItems?.find(item => item.id === shipmentItemId);
  const shipmentItemQuantity = currentShipmentItem?.quantity;
  const expectedQuantity = currentShipmentItem?.expectedQuantity;
  const palletsReceivedCount = currentShipmentItem?.palletsReceivedCount;
  const itemNotes = currentShipmentItem?.itemNotes;
  const totalPieces = currentShipmentItem?.totalPieces;

  const initialValues = useMemo(
    () => ({
      ...fetchAssetValues(asset),
      availableQuantity: asset.availableQuantity,
      manufacturer: shipment?.vendorName,
      poNumber: shipment?.customerPoNumber,
      palletsReceivedCount,
      expectedQuantity,
      shipmentItemQuantity,
      itemNotes,
      totalPieces,
    }),
    [
      asset,
      shipment?.vendorName,
      shipment?.customerPoNumber,
      expectedQuantity,
      shipmentItemQuantity,
      itemNotes,
      totalPieces,
    ]
  );

  const removing = removingAsset || removingShipmentItem;

  const handleSave = async ({
    attachments,
    availableQuantity,
    shipmentItemQuantity,
    expectedQuantity,
    palletsReceivedCount,
    itemNotes,
    totalPieces,
    ...values
  }) => {
    const shipmentCode = params.get("shipmentId");
    const existingReportNumbers = values.receivingReportNumber?.split(", ").filter(Boolean) || [];

    const receivingReportNumber = addShipmentItem
      ? [...existingReportNumbers, shipmentCode]
      : existingReportNumbers;

    const rackLocationsChanges = params.get("rackLocationsChanges")
      ? JSON.parse(params.get("rackLocationsChanges"))
      : [];

    const shipmentItemVariables = {
      id: shipmentItemId,
      quantity: shipmentItemQuantity,
      expectedQuantity,
      palletsReceivedCount,
      itemNotes,
      totalPieces,
      ...(values.palletType && { palletType: values.palletType }),
      rackLocations: rackLocationsChanges.length
        ? rackLocationsChanges
        : shipmentItem.rackLocations,
      ...(values.dimensions && { dimensions: values.dimensions }),
    };

    const input = {
      ...values,
      receivingReportNumber: receivingReportNumber.join(", "),
    };

    await updateShipmentItem({ variables: shipmentItemVariables });
    const response = await updateAsset({ variables: { id, input } });
    onClose();
    return response;
  };

  const handleRemoveAsset = async () => {
    if (project.importedAssets) {
      await removeShipmentItem({ variables: { id: shipmentItemId } });
    } else {
      await removeAsset({ variables: { id: asset.id } });
    }
    onClose();
  };

  const handleClose = () => {
    if (addShipmentItem) {
      toggleRemoveItem();
    } else {
      onClose();
    }
  };

  const toggleRemoveItem = () => setOpenRemoveItem(prev => !prev);

  return (
    <Dialog open fullWidth maxWidth="xs" onClose={handleClose}>
      <DialogTitle sx={{ background: "#f4f6f8", display: "flex", justifyContent: "space-between" }}>
        <Box width="48px" />
        <Typography fontSize={24} fontWeight={500} color="textPrimary">
          Item Details
        </Typography>
        <IconButton onClick={handleClose}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent sx={{ background: "#f4f6f8" }}>
        {loading ? (
          <Box height={258} display="flex" justifyContent="center" alignItems="center">
            <CircularProgress color="primary" />
          </Box>
        ) : (
          <Box>
            <CaptureProvider>
              <ClientsProvider>
                <CollectionProvider>
                  <AssetsProvider>
                    <Formik
                      key={asset?.id}
                      initialValues={initialValues}
                      onSubmit={handleSave}
                      validationSchema={schema}
                    >
                      <FormPage
                        type="item"
                        shipmentId={shipment.id}
                        currentAsset={asset}
                        onCloseModal={onClose}
                        handeRefetch={reFetch}
                        shipmentItem={shipmentItem}
                        removing={removing}
                        toggleRemoveItem={toggleRemoveItem}
                        isDraft={isDraft}
                      />
                    </Formik>
                  </AssetsProvider>
                </CollectionProvider>
              </ClientsProvider>
            </CaptureProvider>
          </Box>
        )}
      </DialogContent>
      {openRemoveItem && (
        <ConfirmDeleteModal
          title="Delete Item"
          descr="Are you sure want to delete the selected item?"
          onClose={toggleRemoveItem}
          onDelete={handleRemoveAsset}
        />
      )}
    </Dialog>
  );
}
