import * as Yup from "yup";
import camelCase from "lodash/camelCase";
import useFields from "../../../../hooks/useFields";

const exclude = [
  "item_id",
  "photo",
  "available_quantity",
  "rack_locations",
  "destination_room_number",
  "attachments",
  "barcode",
  "barcode_number",
  "power",
  "receiving_report_number",
  "weight",
  "minimum_quantity",
  "warehouse_quantity",
  "reserved_quantity",
  "unit_cost",
  "item_id",
  "warranty",
  "po_number",
  "created_at",
  "updated_at",
  "osd_notes",
  "general_notes",
  "quantity_ordered",
];

const useItemSchema = (display, isDraft) => {
  const excludeFields = isDraft ? exclude : exclude.concat(["pallet_type"]);

  const fields = useFields();
  const validationList = fields
    .filter(v => v.enabled)
    .flatMap(v => v.list)
    .map(([key]) => key)
    .filter(key => display[key])
    .filter(key => !excludeFields.includes(key))
    .map(v => [v, camelCase(v)]);
  let validate = {};

  validationList.forEach(([key, camelKey]) => {
    validate[camelKey] = Yup.string()
      .required("Required")
      .nullable();
  });

  if (display.barcode_number) {
    validate.barcode = Yup.string()
      .required("Required")
      .nullable();
  }

  validate.totalPieces = Yup.string()
    .nullable()
    .when(["description", "shipmentItemQuantity"], {
      is: (description, shipmentItemQuantity) => description && shipmentItemQuantity > 0,
      then: () => Yup.string().required("Required"),
      otherwise: () => Yup.string(),
    });

  return Yup.object().shape(validate);
};

export { useItemSchema };
