import React, { useState } from "react";
import Link from "@mui/material/Link";
import IconButton from "@mui/material/IconButton";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import PopupState, { bindMenu, bindTrigger } from "material-ui-popup-state";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import ListItemText from "@mui/material/ListItemText";
import ListItemIcon from "@mui/material/ListItemIcon";
import ReceiptLongIcon from "@mui/icons-material/ReceiptLong";
import SwapHorizIcon from "@mui/icons-material/SwapHoriz";
import DeleteIcon from "@mui/icons-material/Delete";
import InventoryOutlinedIcon from "@mui/icons-material/InventoryOutlined";
import useAuth from "../../../hooks/useAuth";
import ConfirmDeleteModal from "../../../components/images/ConfirmDeleteModal";
import TransferShipmentModal from "./TransferShipmentModal";
import LabelingOptionsModal from "../../../components/modals/LabelingOptionsModal";

export default function InboundActions({ item, onEdit, onRemove }) {
  const { user } = useAuth();
  const [removeItem, setRemoveItem] = useState(false);
  const [openTransferModal, setOpenTransferModal] = useState(false);
  const [openLabelingModal, setOpenLabelingModal] = useState(false);

  const toggleRemoveModal = () => {
    setRemoveItem(v => !v);
  };

  const handleRemoveShipment = () => {
    onRemove();
    setRemoveItem(false);
  };

  const toggleTransferModal = () => {
    setOpenTransferModal(v => !v);
  };

  const toggleLabelingModal = () => {
    setOpenLabelingModal(v => !v);
  };

  return (
    <>
      <IconButton onClick={() => onEdit(item)}>
        <EditOutlinedIcon color="action" />
      </IconButton>
      <PopupState variant="popover" popupId="popup-menu">
        {popupState => (
          <React.Fragment>
            <IconButton {...bindTrigger(popupState)}>
              <MoreVertIcon color="action" />
            </IconButton>
            <Menu {...bindMenu(popupState)}>
              <MenuItem
                onClick={() => popupState.close()}
                component={Link}
                href={`/shipment_pdfs/${item.id}?token=${user.token}`}
                target="_blank"
              >
                <ListItemIcon>
                  <ReceiptLongIcon />
                </ListItemIcon>
                <ListItemText primary="PDF Report" />
              </MenuItem>
              <MenuItem
                onClick={() => {
                  popupState.close();
                  toggleLabelingModal();
                }}
              >
                <ListItemIcon>
                  <InventoryOutlinedIcon />
                </ListItemIcon>
                <ListItemText primary="Item Labels" />
              </MenuItem>
              <MenuItem
                onClick={() => {
                  popupState.close();
                  toggleTransferModal();
                }}
              >
                <ListItemIcon>
                  <SwapHorizIcon />
                </ListItemIcon>
                <ListItemText primary="Transfer Shipment" />
              </MenuItem>
              {user.role === "admin" && (
                <MenuItem
                  onClick={() => {
                    toggleRemoveModal();
                    popupState.close();
                  }}
                >
                  <ListItemIcon>
                    <DeleteIcon />
                  </ListItemIcon>
                  <ListItemText primary="Delete Shipment" />
                </MenuItem>
              )}
            </Menu>
          </React.Fragment>
        )}
      </PopupState>
      {removeItem && (
        <ConfirmDeleteModal
          title="Delete Shipment"
          descr="Are you sure want to delete this shipment?"
          onClose={toggleRemoveModal}
          onDelete={handleRemoveShipment}
        />
      )}
      {openTransferModal && <TransferShipmentModal shipment={item} onClose={toggleTransferModal} />}
      {openLabelingModal && (
        <LabelingOptionsModal onClose={toggleLabelingModal} shipmentId={item.id} />
      )}
    </>
  );
}
